export default (state = {isError: false, isFetching: false, user:{}, customers:[], selectedCustomer:{}}, action) => {
    switch (action.type) {
    case 'LOGIN_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'LOGIN_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
    }
    case 'LOGIN_USER_ACTION_SUCCESS':
    return {
        ...state,
        user: action.payload,
        customers: action.payload.customers,
        selectedCustomer: state.selectedCustomer.id ? state.selectedCustomer : action.payload.customers[0],
        isFetching: false,
        isError: false
    }
    case 'CUSTOMER_ACTION_SWITCH':
    return {
        ...state,
        selectedCustomer: action.payload,
    }
    case 'LOGIN_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    default:
        return state
    }
}
