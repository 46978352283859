export default (state = {isError: false, isFetching: false, user: {} }, action) => {
    switch (action.type) {
    case 'SINGLE_USER_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'SINGLE_USER_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        user: action.payload
    }
    case 'SINGLE_USER_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    default:
        return state
    }
}
