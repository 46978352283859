import { Descriptions } from 'antd';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddressAction } from '../../../actions/addressAction';

class AddressFromUser extends React.Component {

  componentDidMount(){
    this.props.getAddressAction(this.props.match.params.id);
  }

  render(){
    return (
        <Descriptions title="Address" layout="vertical" bordered>
          <Descriptions.Item label="Straat" span={2}>{this.props.address.street}</Descriptions.Item>
          <Descriptions.Item label="Huisnummer">{this.props.address.house_number}</Descriptions.Item>
          <Descriptions.Item label="Postcode">{this.props.address.zipcode}</Descriptions.Item>
          <Descriptions.Item label="Stad" span={2}>{this.props.address.city}</Descriptions.Item>
        </Descriptions>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.addressReducer.isFetching,
      isError: state.addressReducer.isError,
      address: state.addressReducer.address,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
     getAddressAction : bindActionCreators(getAddressAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressFromUser);
