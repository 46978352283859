import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input } from 'antd';
const { TextArea } = Input;

const NewCampaignForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;

        return (
          <Modal
            visible={visible}
            title="Maak een nieuwe campagne aan"
            okText="OK"
            onCancel={onCancel}
            onOk={onCreate}>
            <Form layout="vertical">
                <Form.Item label="Naam">
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Geef een naam op!' }],
                })(<Input />)}
                </Form.Item>
              <Form.Item label="Logo Code">
                {getFieldDecorator('logo', {
                  rules: [{ required: false, message: 'Geef een code op!' }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Beschrijving">
              {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Geef een Beschrijving op!' }],
              })(<TextArea rows={4} />)}
              </Form.Item>
            </Form>
          </Modal>
        );
    }
  }
);

export default NewCampaignForm;
