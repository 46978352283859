import { Row, Col, Spin, Descriptions, Popconfirm, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSingleUserAction } from "../../actions/singleUserAction";
import api from "../../utils/api";

import CreditTransactions from "./subscreens/CreditTransactions";
import AddressFromUser from "./subscreens/AddressFromUser";

class UserDetail extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.getSingleUserAction(params);
  }

  removeUser = () => {
    return api(
      "delete",
      `/api/dashboard/users/${this.props.match.params.id}`,
      null,
      true
    )
      .then((response) => {
        if (response.data.success) {
          window.location = "/users";
        }
      })
      .catch((e) => {
        alert("Fout opgetreden.");
        return false;
      });
  };

  render() {
    if (this.props.isFetching && !this.props.isError) {
      return <Spin />;
    }

    return (
      <div>
        <Row>
          <Col xs={24} md={24} style={{ marginBottom: 20 }}>
            <Descriptions
              title="Gebruikers informatie"
              layout="vertical"
              bordered>
              <Descriptions.Item label="Naam">
                {this.props.user.name}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {this.props.user.email}
              </Descriptions.Item>
              <Descriptions.Item label="Credits">
                {this.props.user.credits}
              </Descriptions.Item>
              <Descriptions.Item label="Account sinds">
                {this.props.user.created_at}
              </Descriptions.Item>
              <Descriptions.Item label="Laatste login">
                {this.props.user.last_login}
              </Descriptions.Item>
              <Descriptions.Item label="Tuin?">
                {this.props.user.has_garden}
              </Descriptions.Item>
              <Descriptions.Item label="Mollie ID">
                {this.props.user.mollie_id}
              </Descriptions.Item>
              <Descriptions.Item label="Geverifieerd?">
                {this.props.user.is_verified}
              </Descriptions.Item>
              <Descriptions.Item label="Campaign code">
                {this.props.user.code}
              </Descriptions.Item>
            </Descriptions>
            <Popconfirm
              title="Weet je het zeker?"
              onConfirm={() => this.removeUser()}
              okText="Yes"
              cancelText="No">
              <Button type="danger" size={"small"}>
                Verwijderen!!!
              </Button>
            </Popconfirm>
          </Col>

          <Col xs={24} md={11}>
            <h3 style={{ marginBottom: 16 }}>Credit Transacties</h3>
            <CreditTransactions {...this.props} />
          </Col>
          <Col xs={24} md={1} />
          <Col xs={24} md={12}>
            <h3 style={{ marginBottom: 16 }}>Opties</h3>
            {this.props.user.garden?.id && (
              <Link
                to={
                  "/garden/" +
                  this.props.user.garden?.id +
                  "/" +
                  this.props.match.params.id
                }>
                Ga naar de tuin van de gebruiker
              </Link>
            )}
            <AddressFromUser {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.singleUserReducer.isFetching,
    isError: state.singleUserReducer.isError,
    user: state.singleUserReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleUserAction: bindActionCreators(getSingleUserAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
