import React from "react";
import PlantOverview from "./screens/plants/PlantOverview";
import PlantDetail from "./screens/plants/PlantDetail";
import Timeline from "./screens/timelines/Timeline";
import FieldOverview from "./screens/field/FieldOverview";
import UserOverview from "./screens/users/UserOverview";
import UserDetail from "./screens/users/UserDetail";
import GardenOverview from "./screens/garden/GardenOverview";
import LotOverview from "./screens/lot/LotOverview";
import CampaignOverview from "./screens/campaign/CampaignOverview";
import CampaignDetail from "./screens/campaign/CampaignDetail";
import Home from "./screens/home/Home";
import Login from "./screens/auth/Login";
import Logout from "./screens/auth/Logout";
import ActionOverview from "./screens/actions/ActionOverview";
import InvoiceOverview from "./screens/invoice/InvoiceOverview";
import ExportScreen from "./screens/export/ExportScreen";
import AppLayout from "./components/AppLayout";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";
import ShopItemsOverview from "./screens/shop-items/ShopItemsOverview";
import ShopItemDetail from "./screens/shop-items/ShopItemDetail";
import ShopOrdersOverview from "./screens/shop-orders/ShopOrdersOverview";
import ShopOrderDetail from "./screens/shop-orders/ShopOrderDetail";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <AuthenticatedRoute exact path="/" component={Home} />
        <AuthenticatedRoute exact path="/plants" component={PlantOverview} />
        <AuthenticatedRoute exact path="/plants/:id" component={PlantDetail} />
        <AuthenticatedRoute exact path="/timelines/:id" component={Timeline} />
        <AuthenticatedRoute exact path="/users" component={UserOverview} />
        <AuthenticatedRoute exact path="/users/:id" component={UserDetail} />
        <AuthenticatedRoute exact path="/actions" component={ActionOverview} />
        <AuthenticatedRoute exact path="/field" component={FieldOverview} />
        <AuthenticatedRoute
          exact
          path="/garden/:id/:user_id"
          component={GardenOverview}
        />
        <AuthenticatedRoute exact path="/lot/:id" component={LotOverview} />
        <AuthenticatedRoute
          exact
          path="/campaigns"
          component={CampaignOverview}
        />
        <AuthenticatedRoute
          exact
          path="/campaign/:id"
          component={CampaignDetail}
        />
        <AuthenticatedRoute
          exact
          path="/invoices"
          component={InvoiceOverview}
        />
        <AuthenticatedRoute exact path="/export" component={ExportScreen} />
        <AuthenticatedRoute
          exact
          path="/shop-items"
          component={ShopItemsOverview}
        />
        <AuthenticatedRoute
          exact
          path="/shop-items/:id"
          component={ShopItemDetail}
        />
        <AuthenticatedRoute
          exact
          path="/shop-orders"
          component={ShopOrdersOverview}
        />
        <AuthenticatedRoute
          exact
          path="/shop-orders/:id"
          component={ShopOrderDetail}
        />
      </Switch>
    </Router>
  );
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("authToken") ? (
        <AppLayout component={<Component {...props} />} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
