import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Typography, Spin, Input, Button } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getSinglePlantAction,
  putSinglePlantAction,
} from "../../../actions/plantAction";

const { Title } = Typography;
const { TextArea } = Input;

class PlantVars extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.getSinglePlantAction(params);
    this.handleInputChange.bind(this);
    this.handleSwitchChange.bind(this);
  }

  state = {
    id: this.props.match.params.id,
    demo: 0,
    name: "",
    code: "",
    herb: null,
    category_id: null,
    description: "",
    active_component: "",
    grow_time: 0,
    cost: 0,
    quantity: 0,
    yield: 0,
    days_between_harvest: 0,
    amount_harvestable: 0,
    from: "",
    till: "",
    days_to_delivered: 0,
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(value);
    console.log(name);

    this.setState({
      [name]: value,
    });
  };

  handleSwitchChange = () => {
    this.setState(
      {
        herb: !this.state.herb,
      },
      () => console.log("State updated", this.state)
    );
  };

  submitChanges = () => {
    console.log(this.state);
    this.props.putSinglePlantAction(this.state);
  };

  render() {
    if (this.props.isFetching && !this.props.isError) {
      return <Spin />;
    } else if (!this.props.isFetching && this.props.isError) {
    }

    if (this.props.isSuccess) {
      this.props.getSinglePlantAction({ id: this.props.match.params.id });
    }

    if (this.state.code !== this.props.currentPlant.code) {
      this.setState(
        {
          name: this.props.currentPlant.name,
          demo: this.props.currentPlant.demo,
          code: this.props.currentPlant.code,
          herb: this.props.currentPlant.herb,
          description: this.props.currentPlant.description,
          active_component: this.props.currentPlant.active_component,
          grow_time: this.props.currentPlant.grow_time,
          cost: this.props.currentPlant.cost,
          quantity: this.props.currentPlant.quantity,
          yield: this.props.currentPlant.yield,
          days_between_harvest: this.props.currentPlant.days_between_harvest,
          amount_harvestable: this.props.currentPlant.amount_harvestable,
          from: this.props.currentPlant.from,
          till: this.props.currentPlant.till,
          days_to_delivered: this.props.currentPlant.days_to_delivered,
        },
        () => console.log("State updated", this.state)
      );
    }

    return (
      <div>
        <Title level={4}>Belangrijkste gegevens</Title>
        <p>
          Opslaan met de knop onderaan! Deze waardes worden niet automatisch
          opgeslagen.
        </p>
        <Form layout="horizontal" labelAlign="left">
          <Form.Item label="Naam" style={{ marginBottom: 0 }}>
            <Input
              defaultValue={this.props.currentPlant.name}
              name="name"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Demo" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.demo}
              name="demo"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Code" style={{ marginBottom: 0 }}>
            <Input
              defaultValue={this.props.currentPlant.code}
              name="code"
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="Kruid" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.herb}
              name="herb"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Beschrijving" style={{ marginBottom: 0 }}>
            <TextArea
              rows={4}
              defaultValue={this.props.currentPlant.description}
              name="description"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Actieve Component" style={{ marginBottom: 0 }}>
            <TextArea
              rows={4}
              defaultValue={this.props.currentPlant.active_component}
              name="active_component"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Rijptijd" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.grow_time}
              name="grow_time"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Prijs in credits" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.cost}
              name="cost"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Hoeveelheid" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.quantity}
              name="quantity"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Dagen tussen oogsten" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.days_between_harvest}
              name="days_between_harvest"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Aantal oogsten" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.amount_harvestable}
              name="amount_harvestable"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Opbrengst per stuk" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.yield}
              name="yield"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Beschikbaar van (Maand / Dag)"
            style={{ marginBottom: 0 }}>
            <Input
              defaultValue={this.props.currentPlant.from}
              name="from"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Beschikbaar tot (Maand / Dag)"
            style={{ marginBottom: 0 }}>
            <Input
              defaultValue={this.props.currentPlant.till}
              name="till"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Levertijd in dagen" style={{ marginBottom: 0 }}>
            <Input
              type="number"
              defaultValue={this.props.currentPlant.days_to_delivered}
              name="days_to_delivered"
              onChange={this.handleInputChange}
            />
          </Form.Item>
        </Form>

        <br />

        <Button onClick={() => this.submitChanges()}>Opslaan</Button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.plantReducer.isFetching,
    isError: state.plantReducer.isError,
    isPutting: state.plantReducer.isPutting,
    isSuccess: state.plantReducer.isSuccess,
    currentPlant: state.plantReducer.currentPlant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSinglePlantAction: bindActionCreators(getSinglePlantAction, dispatch),
    putSinglePlantAction: bindActionCreators(putSinglePlantAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantVars);
