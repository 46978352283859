import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, InputNumber, Select } from "antd";
import { getExActionsAction } from "../../../actions/exActionAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

const UpdatePlantTimelineForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    componentDidMount() {
      this.props.getExActionsAction();
    }

    render() {
      console.log(this.props.numero);

      if (!this.props.plantTimeline[0]) {
        return <div>Loading...</div>;
      }

      if (this.props.isFetching) {
        return <div>Loading...</div>;
      } else if (this.props.isError) {
        return <div>Error...</div>;
      } else {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;

        const actionsItems = this.props.actions.map((item, key) => (
          <Option value={item.id} key={key}>
            {item.name}
          </Option>
        ));

        return (
          <Modal
            visible={visible}
            title="Bewerken"
            okText="OK"
            onCancel={onCancel}
            onOk={onCreate}
          >
            <Form layout="vertical">
              <Form.Item label="ID">
                {getFieldDecorator("id", {
                  initialValue: this.props.plantTimeline[this.props.numero].id,
                  rules: [{ required: true, message: "Geef een id op!" }]
                })(<InputNumber disabled={true} />)}
              </Form.Item>
              <Form.Item label="Plant">
                {getFieldDecorator("plant_id", {
                  initialValue: this.props.match.params.id,
                  rules: [{ required: true, message: "Geef een plant op!" }]
                })(<InputNumber disabled={true} />)}
              </Form.Item>
              <Form.Item label="Dag">
                {getFieldDecorator("day", {
                  initialValue: this.props.plantTimeline[this.props.numero].day,
                  rules: [{ required: true, message: "Geef een dag op!" }]
                })(<InputNumber min={1} max={365} />)}
              </Form.Item>
              <Form.Item label="Actie">
                {getFieldDecorator("action_id", {
                  initialValue: this.props.plantTimeline[this.props.numero]
                    .action_id,
                  rules: [{ required: true, message: "Geef een actie op!" }]
                })(<Select>{actionsItems}</Select>)}
              </Form.Item>
              <Form.Item label="Title">
                {getFieldDecorator("title", {
                  initialValue: this.props.plantTimeline[this.props.numero]
                    .title,
                  rules: [{ required: true, message: "Geef een titel op!" }]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Message">
                {getFieldDecorator("message", {
                  initialValue: this.props.plantTimeline[this.props.numero]
                    .message,
                  rules: [{ required: true, message: "Geef een message op!" }]
                })(<TextArea rows={4} />)}
              </Form.Item>
            </Form>
          </Modal>
        );
      }
    }
  }
);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.exActionReducer.isFetching,
    IsError: state.exActionReducer.isError,
    actions: state.exActionReducer.actions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getExActionsAction: bindActionCreators(getExActionsAction, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePlantTimelineForm);
