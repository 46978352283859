export default (state = {isError: false, isFetching: false, isPosting: false, isDeleting: false, actions: []}, action) => {
    switch (action.type) {
    case 'EX_ACTION_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'EX_ACTION_ACTION_POST':
    return {
        ...state,
        isPosting: true,
        isError: false,
    }
    case 'EX_ACTION_ACTION_DELETE':
    return {
        ...state,
        isDeleting: true,
        isError: false,
    }
    case 'EX_ACTION_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        actions: action.payload
    }
    case 'EX_ACTION_POST_ACTION_SUCCESS':
    return {
        ...state,
        isPosting: false,
        isError: false,
    }
    case 'EX_ACTION_DELETE_ACTION_SUCCESS':
    return {
        ...state,
        isDeleting: false,
        isError: false,
    }
    case 'EX_ACTION_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false,
        isPosting: false,
        isDeleting: false
    }
    default:
        return state
    }
}
