import { PlusOutlined } from "@ant-design/icons";
import { Card, Row, Col, Spin, Typography, Button } from "antd";
import {
  faExclamationCircle,
  faTint,
  faCarrot,
  faBox,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
// import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFieldsAction, postFieldsAction } from "../../actions/fieldAction";

import NewRowForm from "./forms/NewRow";

const { Title } = Typography;

class FieldOverview extends React.Component {
  componentDidMount() {
    this.props.getFieldsAction();
  }

  state = {
    params: {},
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  componentDidUpdate() {
    if (this.props.isSuccess) {
      this.props.getFieldsAction();
    }
  }

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postFieldsAction(values);
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  _renderLotCard(item) {
    if (item.garden_id) {
      return (
        <Card.Grid
          key={item.id}
          style={{
            width: "25%",
          }}
          onClick={() =>
            (window.location.href =
              "/garden/" + item.garden_id + "/" + item.user_id)
          }>
          {item.row}.{item.block}
          {item.action_needed && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "red", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {item.needs_water === 1 && (
            <FontAwesomeIcon
              icon={faTint}
              style={{ color: "grey", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {item.needs_water === 2 && (
            <FontAwesomeIcon
              icon={faTint}
              style={{ color: "blue", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {item.harvesting === 1 && (
            <FontAwesomeIcon
              icon={faCarrot}
              style={{ color: "orange", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {item.order_zero === 1 && (
            <FontAwesomeIcon
              icon={faBox}
              style={{ color: "red", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {!item.user_id && (
            <FontAwesomeIcon
              icon={faUser}
              style={{ color: "red", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {item.order_one === 1 && (
            <FontAwesomeIcon
              icon={faBox}
              style={{ color: "orange", marginRight: 5, marginLeft: 5 }}
            />
          )}
          {item.order_two === 1 && (
            <FontAwesomeIcon
              icon={faBox}
              style={{ color: "green", marginRight: 5, marginLeft: 5 }}
            />
          )}
        </Card.Grid>
      );
    } else {
      return (
        <Card.Grid
          key={item.id}
          hoverable={false}
          style={{ width: "25%", backgroundColor: "#D3D3D3" }}>
          {item.row}.{item.block}
        </Card.Grid>
      );
    }
  }

  render() {
    if (this.props.isFetching && !this.props.isError) {
      return <Spin />;
    } else if (this.props.isError) {
      return <div>Error...</div>;
    } else {
      let lastRow = 1;

      const content = this.props.fields.map((item, key) => {
        // return this._renderLotCard(item);
        if (item.row !== lastRow) {
          lastRow = item.row;
          return (
            <div>
              <Card.Grid
                key={item.id + "boi"}
                hoverable={false}
                style={{ width: "100%" }}></Card.Grid>
              {this._renderLotCard(item)}
            </div>
          );
        } else {
          return this._renderLotCard(item);
        }
      });

      return (
        <div>
          <h1>Het veld</h1>

          <Card>{content}</Card>

          <Card>
            <Card.Grid style={{ width: "100%" }}>
              <Title level={4}>Voeg een rij toe</Title>
              <Button
                onClick={this.showModal}
                type="primary"
                icon={<PlusOutlined />}
              />
            </Card.Grid>
          </Card>

          <NewRowForm
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            onCreate={this.handleCreate}
            {...this.props}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.fieldReducer.isFetching,
    isError: state.fieldReducer.isError,
    isPosting: state.fieldReducer.isPosting,
    isSuccess: state.fieldReducer.isSuccess,
    fields: state.fieldReducer.fields,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFieldsAction: bindActionCreators(getFieldsAction, dispatch),
    postFieldsAction: bindActionCreators(postFieldsAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldOverview);
