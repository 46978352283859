export default (
  state = { isError: false, isPutting: false, isSuccess: false },
  action
) => {
  switch (action.type) {
    case "ORDER_STATUS_ACTION_PUT":
      return {
        ...state,
        isPutting: true,
        isError: false,
        isSuccess: false,
      };
    case "ORDER_STATUS_ACTION_PUT_SUCCESS":
      return {
        ...state,
        isPutting: false,
        isError: false,
        isSuccess: true,
      };
    case "ORDER_STATUS_ACTION_ERROR":
      return {
        ...state,
        isError: true,
        isPutting: false,
        isSuccess: false,
      };
    default:
      return state;
  }
};
