import api from './../utils/api'

export const getGardenInformationAction = (params) => dispatch => {
  dispatch({
    type: 'GARDEN_INFORMATION_ACTION_FETCH'
  })
  return api('get','/api/gardens', params, true).then((response) => {
    console.log(response.data);
    if(response.data.success) {
      dispatch({
        type: 'GARDEN_INFORMATION_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'GARDEN_INFORMATION_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'GARDEN_INFORMATION_ACTION_ERROR',
    })
    return false;
  });
}
