export default (state = {isError: false, isFetching: false, isPosting: false, campaigns: [], currentCampaign: {} }, action) => {
    switch (action.type) {
    case 'CAMPAIGN_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
        isPosting: false,
    }
    case 'SINGLE_CAMPAIGN_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
        isPosting: false,
    }
    case 'SINGLE_CAMPAIGN_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
        currentCampaign: action.payload
    }
    case 'CAMPAIGN_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
        campaigns: action.payload
    }
    case 'CAMPAIGN_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isPosting: false,
        isFetching: false
    }
    case 'CAMPAIGN_ACTION_POST':
    return {
        ...state,
        isError: false,
        isPosting: true,
        isFetching: false
    }
    case 'CAMPAIGN_POST_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
    }
    default:
        return state
    }
}
