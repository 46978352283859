import "@ant-design/compatible/assets/index.css";
import { Button, Popconfirm, Space, Table, Typography } from "antd";
import Text from "antd/lib/typography/Text";

import React from "react";
import { Link } from "react-router-dom";
import api from "../../utils/api";

const { Title } = Typography;

class ShopOrderDetail extends React.Component {
  componentDidMount() {
    api(
      "get",
      `/api/dashboard/shop-orders/${this.props.match.params.id}`,
      null,
      true
    ).then((response) => {
      if (response.data.success) {
        this.setState(response.data.data);
      }
    });
  }

  confirmOrder = () => {
    return api(
      "patch",
      `/api/dashboard/shop-orders/${this.props.match.params.id}/confirm`,
      null,
      true
    )
      .then((response) => {
        if (response.data.success) {
          window.location = "/shop-orders";
        }
      })
      .catch((e) => {
        alert("Fout opgetreden.");
        return false;
      });
  };

  state = {
    id: this.props.match.params.id,
  };

  // submitChanges = () => {
  //   api(
  //     "patch",
  //     `/api/dashboard/shop-items/${this.props.match.params.id}`,
  //     this.state,
  //     true
  //   ).then((response) => {
  //     if (response.data.success) {
  //       notification["success"]({
  //         message: "Aangepast",
  //         description: "Het item is succesvol opgeslagen.",
  //       });
  //     }
  //   });
  // };

  render() {
    const columns = [
      {
        title: "Hoeveelheid",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Prijs per stuk",
        dataIndex: "price",
        key: "price",
      },
    ];

    return (
      <div>
        <Title level={4}>Bestelling van {this.state.user}</Title>

        <Space direction="vertical">
          <Link to={"/users/" + this.state.user_id}>Gebruikersprofiel</Link>

          <Text>
            Deze bestelling is een{" "}
            <Text strong>{this.state.delivery_method}</Text>
          </Text>

          <Text>De totaalprijs is {this.state.price}</Text>

          {this.state.garden_order && (
            <Text>Tuin dient ook meegenomen te worden in deze bestelling</Text>
          )}

          {this.state.status !== "finished" && (
            <Popconfirm
              title="Weet je het zeker?"
              onConfirm={() => this.confirmOrder()}
              okText="Yes"
              cancelText="No">
              <Button type="primary" size={"small"}>
                Afronden
              </Button>
            </Popconfirm>
          )}
        </Space>
        <Table dataSource={this.state.order_lines} columns={columns} />
      </div>
    );
  }
}

export default ShopOrderDetail;
