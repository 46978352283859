export default (
  state = {
    isError: false,
    isFetching: false,
    isPosting: false,
    creditVouchers: [],
    currentVoucher: {}
  },
  action
) => {
  switch (action.type) {
    case "CREDIT_VOUCHER_ACTION_FETCH":
      return {
        ...state,
        isFetching: true,
        isError: false,
        isPosting: false
      };
    case "SINGLE_CREDIT_VOUCHER_ACTION_FETCH":
      return {
        ...state,
        isFetching: true,
        isError: false,
        isPosting: false
      };
    case "SINGLE_CREDIT_VOUCHER_ACTION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
        currentVoucher: action.payload
      };
    case "CREDIT_VOUCHER_ACTION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
        creditVouchers: action.payload
      };
    case "CREDIT_VOUCHER_ACTION_ERROR":
      return {
        ...state,
        isError: true,
        isPosting: false,
        isFetching: false
      };
    case "CREDIT_VOUCHER_ACTION_POST":
      return {
        ...state,
        isError: false,
        isPosting: true,
        isFetching: false
      };
    case "CREDIT_VOUCHER_POST_ACTION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false
      };
    default:
      return state;
  }
};
