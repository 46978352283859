import { Button, Popconfirm } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLotAction } from "../../../actions/lotAction";
import { putOrderStatus } from "../../../actions/orderStatusAction";

class OrderStatus extends React.Component {
  _changeOrderStatus = () => {
    let params = {
      id: this.props.match.params.id,
    };
    this.props.putOrderStatus(params);
    setTimeout(() => {
      this.props.getLotAction(params);
    }, 1000);
  };

  render() {
    return (
      <Popconfirm
        title="Are you sure?"
        onConfirm={() => this._changeOrderStatus()}
        okText="Yes"
        cancelText="No">
        <Button type="primary">Daadwerkelijk zaaien!</Button>
      </Popconfirm>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isPutting: state.orderStatusReducer.isPutting,
    isError: state.orderStatusReducer.isError,
    isSuccess: state.orderStatusReducer.isSuccess,
    lot: state.lotReducer.lot,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLotAction: bindActionCreators(getLotAction, dispatch),
    putOrderStatus: bindActionCreators(putOrderStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus);
