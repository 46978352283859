import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Typography,
  Input,
  Button,
  notification,
  InputNumber,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import React from "react";
import api from "../../utils/api";

const { Title } = Typography;
const { TextArea } = Input;

class ShopItemDetail extends React.Component {
  componentDidMount() {
    api(
      "get",
      `/api/dashboard/shop-items/${this.props.match.params.id}`,
      null,
      true
    ).then((response) => {
      if (response.data.success) {
        this.setState(response.data.data);
      }
    });

    this.handleInputChange.bind(this);
  }

  state = {
    id: this.props.match.params.id,
    name: "",
    description: "",
    price: "",
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  submitChanges = () => {
    api(
      "patch",
      `/api/dashboard/shop-items/${this.props.match.params.id}`,
      this.state,
      true
    ).then((response) => {
      if (response.data.success) {
        notification["success"]({
          message: "Aangepast",
          description: "Het item is succesvol opgeslagen.",
        });
      }
    });
  };

  render() {
    const props = {
      multiple: false,
      beforeUpload: async (file) => {
        let formData = new FormData();
        formData.append("file", file);

        api(
          "post",
          `/api/dashboard/shop-items/${this.props.match.params.id}/image`,
          formData,
          true
        ).then((response) => {
          this.setState(response.data.data);
          return false;
        });
      },
    };

    return (
      <div>
        <Title level={4}>Belangrijkste gegevens</Title>
        <p>
          Opslaan met de knop onderaan! Deze waardes worden niet automatisch
          opgeslagen.
        </p>
        <Form layout="vertical" labelAlign="left">
          <Form.Item label="Naam" style={{ marginBottom: 0 }}>
            <Input
              value={this.state.name}
              name="name"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Beschrijving" style={{ marginBottom: 0 }}>
            <TextArea
              rows={4}
              value={this.state.description}
              name="description"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Prijs" style={{ marginBottom: 0 }}>
            <InputNumber
              min={0}
              max={365}
              name="price"
              value={this.state.price}
              step="0.10"
              onChange={(value) => {
                this.setState({
                  price: value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Aantal beschikbaar" style={{ marginBottom: 0 }}>
            <InputNumber
              min={0}
              max={365}
              name="price"
              value={this.state.stock_amount}
              onChange={(value) => {
                this.setState({
                  stock_amount: value,
                });
              }}
            />
          </Form.Item>
        </Form>

        <br />
        <Button onClick={() => this.submitChanges()}>Opslaan</Button>
        <br />
        <br />

        <Title level={4}>Foto</Title>

        <Upload {...props} accept=".jpg,.png">
          <Button>
            <UploadOutlined /> Select File
          </Button>
        </Upload>

        <img src={this.state.image?.original_url} width={"100%"} alt="Logo" />
      </div>
    );
  }
}

export default ShopItemDetail;
