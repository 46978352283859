import React, { Component } from 'react';
import { Row, Col} from 'antd';
import Motd from './subscreens/Motd';

export default class Home extends Component {
    simpleAction = (event) => {
        this.props.simpleAction();
    }

    render() {
      return (
       <div>
        <Col xs={24} md={6}>
          <Motd {...this.props}/>
        </Col>
       </div>
      );
 }
}
