import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logoutAction } from '../../actions/authAction';

class Logout extends Component {

  componentDidMount(){
    this.props.logoutAction();
    this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <p>Logging out...</p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.authReducer.isFetching,
    isError : state.authReducer.isError,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction : bindActionCreators(logoutAction, dispatch),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
