import {
  Button,
  Popconfirm,
  Descriptions,
  Card,
  Typography,
  Breadcrumb,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getGardenAction,
  updateWaterGardenAction,
  updateWaterGardenActionPositive,
} from "../../actions/gardenAction";
import { getGardenInformationAction } from "../../actions/gardenInformationAction";
import GardenInformation from "./GardenInformation";
import api from "../../utils/api";
import {
  faExclamationCircle,
  faCarrot,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Text } = Typography;

class GardenOverview extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.getGardenAction(params);
  }

  _alterStatus = () => {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.updateWaterGardenAction(params);
    setTimeout(() => {
      this.props.getGardenInformationAction(params);
    }, 1000);
  };

  _alterStatusPositive = () => {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.updateWaterGardenActionPositive(params);
    setTimeout(() => {
      this.props.getGardenInformationAction(params);
    }, 1000);
  };

  blockGarden = (status) => {
    return api(
      "patch",
      `/api/gardens/${this.props.match.params.id}/block`,
      { status: status },
      true
    )
      .then((response) => {
        if (response.data.success) {
          window.location.reload();
        }
      })
      .catch((e) => {
        alert("Fout opgetreden.");
        return false;
      });
  };

  render() {
    const content = this.props.garden.map((item, key) => {
      if (item.plant_id) {
        return (
          <Card.Grid
            key={key}
            onClick={() => (window.location.href = "/lot/" + item.id)}>
            {item.index}. {item.plant_name}
            {item.action_needed && (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "red", marginRight: 5, marginLeft: 5 }}
              />
            )}
            {item.harvesting === 1 && (
              <FontAwesomeIcon
                icon={faCarrot}
                style={{ color: "orange", marginRight: 5, marginLeft: 5 }}
              />
            )}
            {item.order_status === 0 && (
              <FontAwesomeIcon
                icon={faBox}
                style={{ color: "red", marginRight: 5, marginLeft: 5 }}
              />
            )}
            {item.order_status === 1 && (
              <FontAwesomeIcon
                icon={faBox}
                style={{ color: "orange", marginRight: 5, marginLeft: 5 }}
              />
            )}
            {item.order_status === 2 && (
              <FontAwesomeIcon
                icon={faBox}
                style={{ color: "green", marginRight: 5, marginLeft: 5 }}
              />
            )}
          </Card.Grid>
        );
      } else {
        return (
          <Card.Grid key={key} hoverable={false}>
            {item.index} leeg
          </Card.Grid>
        );
      }
    });

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/field">Veld</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="#">Tuin</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <br />

        <Descriptions
          title="Tuin informatie"
          layout="vertical"
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label="Positie">
            <GardenInformation {...this.props} />
          </Descriptions.Item>
          <Descriptions.Item label="Gebruiker">
            {this.props.gardenInformation.user_id ? (
              <Link to={"/users/" + this.props.match.params.user_id}>
                {this.props.gardenInformation.name}
              </Link>
            ) : (
              <p>Tuin heeft geen gebruiker.</p>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Water status">
            {this.props.gardenInformation.needs_water === 0 && (
              <div>
                <Text style={{ color: "green" }}>Alles in orde</Text>
                <br />
                <Popconfirm
                  title="Are you sure you want to alert the user?"
                  onConfirm={() => this._alterStatus()}
                  okText="Yes"
                  cancelText="No">
                  <Button type="primary" size={"small"}>
                    Actie vereist
                  </Button>
                </Popconfirm>
              </div>
            )}
            {this.props.gardenInformation.needs_water === 1 && (
              <div>
                <Text style={{ color: "red" }}>Klant moet nog water geven</Text>
                <br />
              </div>
            )}
            {this.props.gardenInformation.needs_water === 2 && (
              <div>
                <Text style={{ color: "blue" }}>
                  Geef terugkoppeling dat water is gegeven
                </Text>
                <br />
                <Popconfirm
                  title="Are you sure you want to alert the user?"
                  onConfirm={() => this._alterStatusPositive()}
                  okText="Yes"
                  cancelText="No">
                  <Button type="primary" size={"small"}>
                    Water gegeven
                  </Button>
                </Popconfirm>
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Tuin vergrendelen">
            {this.props.gardenInformation.blocked ? (
              <Popconfirm
                title="Weet je het zeker?"
                onConfirm={() => this.blockGarden(false)}
                okText="Yes"
                cancelText="No">
                <Button type="primary" size={"small"}>
                  Vrijgeven
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Weet je het zeker?"
                onConfirm={() => this.blockGarden(true)}
                okText="Yes"
                cancelText="No">
                <Button type="primary" size={"small"}>
                  Blokkeren
                </Button>
              </Popconfirm>
            )}
          </Descriptions.Item>
        </Descriptions>

        <br />
        <br />

        <Card>{content}</Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.gardenReducer.isFetching,
    isError: state.gardenReducer.isError,
    garden: state.gardenReducer.garden,
    gardenInformation: state.gardenInformationReducer.gardenInformation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGardenAction: bindActionCreators(getGardenAction, dispatch),
    updateWaterGardenAction: bindActionCreators(
      updateWaterGardenAction,
      dispatch
    ),
    updateWaterGardenActionPositive: bindActionCreators(
      updateWaterGardenActionPositive,
      dispatch
    ),
    getGardenInformationAction: bindActionCreators(
      getGardenInformationAction,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GardenOverview);
