import api from '../utils/api'

export const getPlantTimelineAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_TIMELINE_ACTION_FETCH'
  })
  return api('get','/api/planttimelines', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'PLANT_TIMELINE_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_TIMELINE_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_TIMELINE_ACTION_ERROR',
    })
    return false;
  });
}

export const postPlantTimelineAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_TIMELINE_ACTION_POST'
  })
  return api('post','/api/planttimelines', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_TIMELINE_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_TIMELINE_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_TIMELINE_ACTION_ERROR',
    })
    return false;
  });
}

export const updatePlantTimelineAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_TIMELINE_ACTION_UPDATE'
  })
  return api('put','/api/planttimelines', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_TIMELINE_UPDATE_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_TIMELINE_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_TIMELINE_ACTION_ERROR',
    })
    return false;
  });
}

export const deletePlantTimelineAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_TIMELINE_ACTION_DELETE'
  })
  return api('delete','/api/planttimelines', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_TIMELINE_DELETE_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_TIMELINE_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_TIMELINE_ACTION_ERROR',
    })
    return false;
  });
}
