import api from "../utils/api";

export const getShopOrders = () => (dispatch) => {
  dispatch({
    type: "SHOP_ORDERS_ACTION_FETCH",
  });
  return api("get", "/api/dashboard/shop-orders", null, true)
    .then((response) => {
      if (response.data.success) {
        console.log(response.data.data);
        dispatch({
          type: "SHOP_ORDERS_ACTION_SUCCESS",
          payload: response.data.data,
        });
        return true;
      } else {
        dispatch({
          type: "SHOP_ORDERS_ACTION_ERROR",
        });
        return false;
      }
    })
    .catch((e) => {
      dispatch({
        type: "SHOP_ORDERS_ACTION_ERROR",
      });
      return false;
    });
};

// export const postShopItem = (params) => (dispatch) => {
//   dispatch({
//     type: "SHOP_ORDERS_ACTION_POST",
//   });
//   return api("post", "/api/dashboard/shop-orders", params, true)
//     .then((response) => {
//       if (response.data.success) {
//         console.log(response.data.data);
//         dispatch({
//           type: "SHOP_ORDERS_ACTION_POST_SUCCESS",
//         });
//         return true;
//       } else {
//         dispatch({
//           type: "SHOP_ORDERS_ACTION_ERROR",
//         });
//         return false;
//       }
//     })
//     .catch((e) => {
//       dispatch({
//         type: "SHOP_ORDERS_ACTION_ERROR",
//       });
//       return false;
//     });
// };
