import api from '../utils/api'

export const getCampaignsAction = (params) => dispatch => {
  params = params || {};

  dispatch({
    type: 'CAMPAIGN_ACTION_FETCH'
  })
  return api('get','/api/campaigns', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'CAMPAIGN_ACTION_SUCCESS',
        payload: response.data
      });
      return true;
    } else {
      dispatch({
        type: 'CAMPAIGN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'CAMPAIGN_ACTION_ERROR',
    })
    return false;
  });
}

export const postCampaignAction = (params) => dispatch => {
  dispatch({
    type: 'CAMPAIGN_ACTION_POST'
  })
  return api('post','/api/campaigns', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'CAMPAIGN_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'CAMPAIGN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'CAMPAIGN_ACTION_ERROR',
    })
    return false;
  });
}

export const postCampaignVouchersAction = (params) => dispatch => {
  dispatch({
    type: 'CAMPAIGN_ACTION_POST'
  })
  return api('post','/api/campaigns/vouchers', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'CAMPAIGN_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'CAMPAIGN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'CAMPAIGN_ACTION_ERROR',
    })
    return false;
  });
}

export const postCampaignCreditVouchersAction = (params) => dispatch => {
  dispatch({
    type: 'CAMPAIGN_ACTION_POST'
  })
  return api('post','/api/campaigns/creditvouchers', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'CAMPAIGN_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'CAMPAIGN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'CAMPAIGN_ACTION_ERROR',
    })
    return false;
  });
}
