import { Table, Input, Button } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUsersAction } from "../../actions/userAction";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

class UserOverview extends React.Component {
  componentDidMount() {
    this.props.getUsersAction();
  }

  state = {
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Naam",
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "Credits",
        dataIndex: "credits",
        key: "credits",
        sorter: {
          compare: (a, b) => a.credits - b.credits,
          multiple: 1,
        },
      },
      {
        title: "Geregistreerd op",
        dataIndex: "created_at",
        key: "created_at",
        sorter: {
          compare: (a, b) => a.created_at > b.created_at,
          multiple: 1,
        },
      },
      {
        title: "Demo dagen over",
        key: "demo_left",
        render: (text, record) => (
          <p style={record.demo_left > 0 ? { color: "green" } : { color: "red" }}>
            {record.demo_left}
          </p>
        ),
        sorter: {
          compare: (a, b) => a.demo_left - b.demo_left,
          multiple: 1,
        },
      },
      {
        title: "Tuin",
        key: "has_garden",
        render: (text, record) => (
          <p style={record.has_garden ? { color: "green" } : { color: "red" }}>
            {record.has_garden ? "ja" : "nee"}
          </p>
        ),
        sorter: {
          compare: (a, b) => a.has_garden - b.has_garden,
          multiple: 1,
        },
      },
    ];

    return (
      <div>
        <h1>Alle Gebruikers</h1>
        <Table
          rowKey="id"
          loading={this.props.isFetching}
          bordered={true}
          dataSource={this.props.users}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                window.location = "/users/" + record.id;
              }, // click row
              onDoubleClick: event => {}, // double click row
              onContextMenu: event => {}, // right button click row
              onMouseEnter: event => {}, // mouse enter row
              onMouseLeave: event => {}, // mouse leave row
            };
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.userReducer.isFetching,
    isError: state.userReducer.isError,
    users: state.userReducer.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsersAction: bindActionCreators(getUsersAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview);
