import { Button, Popconfirm } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLotAction, deleteLotAction } from "../../../actions/lotAction";

class DeleteLot extends React.Component {
  _deleteLot = () => {
    let params = {
      id: this.props.match.params.id,
    };
    console.log(params);
    this.props.deleteLotAction(params);
    setTimeout(() => {
      this.props.getLotAction(params);
    }, 1000);
  };

  render() {
    return (
      <Popconfirm
        title="Are you sure?"
        onConfirm={() => this._deleteLot()}
        okText="Yes"
        cancelText="No">
        <Button
          type="danger"
          style={{ marginBottom: 12, marginRight: 10 }}>
          Maak het perceel leeg!
        </Button>
      </Popconfirm>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.lotReducer.isFetching,
    isError: state.lotReducer.isError,
    lot: state.lotReducer.lot,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLotAction: bindActionCreators(getLotAction, dispatch),
    deleteLotAction: bindActionCreators(deleteLotAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLot);
