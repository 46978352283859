import { Table, Divider, Button } from 'antd';
import React from 'react';
import { Link } from "react-router-dom"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getExActionsAction, postExActionAction } from '../../actions/exActionAction';
import NewActionForm from './forms/NewAction'

class ActionOverview extends React.Component {
  componentDidMount(){
    this.props.getExActionsAction()
  }

  state = {
      params: {},
      visible: false,
  };

  showModal = () => {
      this.setState({ visible: true });
  };

  handleCancel = () => {
      this.setState({ visible: false });
  };

  handleCreate = () => {
      const { form } = this.formRef.props;
      form.validateFields((err, values) => {
          if (err) {
              return;
          }

          console.log('Received values of form: ', values);
          form.resetFields();
          this.props.postExActionAction(values);
          if (this.props.isError === false && this.props.isPosting === false) {
              console.log("Hallo");
              this.props.getExActionsAction();
              this.setState({ visible: false });
          }
          console.log("AAR");
      });
  };

  saveFormRef = formRef => {
      this.formRef = formRef;
  };

  render(){
    const columns = [
      {
        title: 'Actie',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Beschrijving',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Link  to={`/actions/detail/${record.id}`} disabled>Details</Link>
            <Divider type="vertical" />
            <Link  to={`/actions/delete/${record.id}`} disabled>Verwijderen</Link>
          </span>
        ),
     }
    ];

    return (
      <div>
        <h1>Alle Acties</h1>
        <Button type="primary" style={{marginBottom: 12}} onClick={this.showModal}>Voeg Toe</Button>
        <Table rowKey="id" loading={this.props.isFetching} bordered={true} dataSource={this.props.actions} columns={columns} pagination={{ pageSize: 20 }}/>
        <NewActionForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
           {...this.props} />
      </div>
    );

  }

}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.exActionReducer.isFetching,
      isError: state.exActionReducer.isError,
      isPosting: state.exActionReducer.isPosting,
      actions: state.exActionReducer.actions,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
     getExActionsAction : bindActionCreators(getExActionsAction, dispatch),
     postExActionAction: bindActionCreators(postExActionAction, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionOverview);
