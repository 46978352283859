import { Table, Button, Space, Popconfirm, notification } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getPlantDiscount,
  postPlantDiscount,
  deletePlantDiscount,
} from "../../../actions/plantDiscountAction";
import NewPlantDiscount from "../forms/NewPlantDiscount";

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "Actie uitgevoerd",
    description: "De korting is met succes toegevoegd!",
  });
};

class PlantDiscount extends React.Component {
  componentDidMount() {
    let params = {
      plant_id: this.props.match.params.id,
    };

    this.setState({ params: params });

    this.props.getPlantDiscount(this.props.match.params.id);
  }

  componentDidUpdate() {
    if (this.props.isSuccess) {
      this.props.getPlantDiscount(this.props.match.params.id);
    }
  }

  state = {
    params: {},
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values["plant_id"] = this.state.params.plant_id;
      values["from"] = values["from"].format("YYYY-MM-DD");
      values["till"] = values["till"].format("YYYY-MM-DD");
      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postPlantDiscount(values);
      this.setState({ visible: false });
      openNotificationWithIcon("success");
      setTimeout(() => {
        this.props.getPlantDiscount(this.props.match.params.id);
      }, 1000);
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  confirmDelete = (id) => {
    this.props.deletePlantDiscount(id);
    setTimeout(() => {
      this.props.getPlantDiscount(this.props.match.params.id);
    }, 1000);
  };

  render() {
    const columns = [
      {
        title: "Credits",
        dataIndex: "credits",
        key: "credits",
      },
      {
        title: "Titel",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Vanaf",
        dataIndex: "from",
        key: "from",
      },
      {
        title: "Tot",
        dataIndex: "till",
        key: "till",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => this.confirmDelete(record.id)}
              okText="Yes"
              cancelText="No">
              <a>Verwijder</a>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Voeg Toe
        </Button>
        <Table
          rowKey="id"
          bordered={true}
          loading={this.props.isFetching}
          dataSource={this.props.plantDiscount}
          columns={columns}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: (event) => {
          //       window.location = "/plants/" + record.id;
          //     }, // click row
          //     onDoubleClick: (event) => {}, // double click row
          //     onContextMenu: (event) => {}, // right button click row
          //     onMouseEnter: (event) => {}, // mouse enter row
          //     onMouseLeave: (event) => {}, // mouse leave row
          //   };
          // }}
        />
        <NewPlantDiscount
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.plantDiscountReducer.isFetching,
    isError: state.plantDiscountReducer.isError,
    isPosting: state.plantDiscountReducer.isPosting,
    isDeleting: state.plantDiscountReducer.isDeleting,
    isSuccess: state.plantDiscountReducer.isSuccess,
    plantDiscount: state.plantDiscountReducer.plantDiscount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlantDiscount: bindActionCreators(getPlantDiscount, dispatch),
    postPlantDiscount: bindActionCreators(postPlantDiscount, dispatch),
    deletePlantDiscount: bindActionCreators(deletePlantDiscount, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantDiscount);
