import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Spin, Input, Button } from 'antd';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPlantNutritionalsAction, putPlantNutritionalsAction } from '../../../actions/plantNutritionalsAction';

const { Title } = Typography;

class PlantNutritionals extends React.Component {

  componentDidMount(){
    let params = {
        plant_id : this.props.match.params.id
    }

    this.props.getPlantNutritionalsAction(params);
  }

  state = {
    id: 0,
    plant_id: 0,
    calorie: 0,
    fat: 0,
    fibers: 0,
    folic: 0,
    calcium: 0,
    protein: 0,
    carb: 0,
    vitamin_a: 0,
    vitamin_b: 0,
    calium: 0,
    iron: 0,
    vitamin_k: 0,
    vitamin_e: 0,
  };

  handleInputChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
        [name] : value
    });
  }

  submitChanges = () => {
      console.log(this.state);
      this.props.putPlantNutritionalsAction(this.state);
  }

  render(){
    if (this.props.isFetching && !this.props.isError) {
      return (<Spin />)
    } else if (!this.props.isFetching && this.props.isError) {

    }

    if (this.state.plant_id !== this.props.nutritional.plant_id) {
        this.setState({
            id: this.props.nutritional.id,
            plant_id: this.props.nutritional.plant_id,
            calorie: this.props.nutritional.calorie,
            fibers: this.props.nutritional.fibers,
            folic: this.props.nutritional.folic,
            fat: this.props.nutritional.fat,
            calcium: this.props.nutritional.calcium,
            protein: this.props.nutritional.protein,
            carb: this.props.nutritional.carb,
            vitamin_a: this.props.nutritional.vitamin_a,
            vitamin_b: this.props.nutritional.vitamin_b,
            calium: this.props.nutritional.calium,
            iron: this.props.nutritional.iron,
            vitamin_k: this.props.nutritional.vitamin_k,
            vitamin_e: this.props.nutritional.vitamin_e,
        },()=>console.log('State updated', this.state))
    }

    return(
      <div>
          <Title level={4}>Voedingswaarden</Title>
          <p>Opslaan met de knop onderaan! Deze waardes worden niet automatisch opgeslagen.</p>
          <Form>
            <Form.Item label="Calorieën" style={{marginBottom: 0}}>
              <Input type="number" name="calorie" onChange={this.handleInputChange} defaultValue={this.props.nutritional.calorie}/>
            </Form.Item>
            <Form.Item label="Fibers" style={{marginBottom: 0}}>
              <Input type="number" name="fibers" onChange={this.handleInputChange} defaultValue={this.props.nutritional.fibers}/>
            </Form.Item>
            <Form.Item label="Folic" style={{marginBottom: 0}}>
              <Input type="number" name="folic" onChange={this.handleInputChange} defaultValue={this.props.nutritional.folic}/>
            </Form.Item>
            <Form.Item label="Vet" style={{marginBottom: 0}}>
              <Input type="number" name="fat" onChange={this.handleInputChange} defaultValue={this.props.nutritional.fat}/>
            </Form.Item>
            <Form.Item label="Calcium" style={{marginBottom: 0}}>
              <Input type="number" name="calcium" onChange={this.handleInputChange} defaultValue={this.props.nutritional.calcium}/>
            </Form.Item>
            <Form.Item label="Protein" style={{marginBottom: 0}}>
              <Input type="number" name="protein" onChange={this.handleInputChange} defaultValue={this.props.nutritional.protein}/>
            </Form.Item>
            <Form.Item label="Carb" style={{marginBottom: 0}}>
              <Input type="number" name="carb" onChange={this.handleInputChange} defaultValue={this.props.nutritional.carb}/>
            </Form.Item>
            <Form.Item label="Vitamine A" style={{marginBottom: 0}}>
              <Input type="number" name="vitamin_a" onChange={this.handleInputChange} defaultValue={this.props.nutritional.vitamin_a}/>
            </Form.Item>
            <Form.Item label="Vitamine B" style={{marginBottom: 0}}>
              <Input type="number" name="vitamin_b" onChange={this.handleInputChange} defaultValue={this.props.nutritional.vitamin_b}/>
            </Form.Item>
            <Form.Item label="Calium" style={{marginBottom: 0}}>
              <Input type="number" name="clium" onChange={this.handleInputChange} defaultValue={this.props.nutritional.calium}/>
            </Form.Item>
            <Form.Item label="Iron" style={{marginBottom: 0}}>
              <Input type="number" name="iron" onChange={this.handleInputChange} defaultValue={this.props.nutritional.iron}/>
            </Form.Item>
            <Form.Item label="Vitamine K" style={{marginBottom: 0}}>
              <Input type="number" name="vitamin_k" onChange={this.handleInputChange} defaultValue={this.props.nutritional.vitamin_k}/>
            </Form.Item>
            <Form.Item label="Vitamine E" style={{marginBottom: 0}}>
              <Input type="number" name="vitamin_e" onChange={this.handleInputChange} defaultValue={this.props.nutritional.vitamin_e}/>
            </Form.Item>
          </Form>

          <br/>

          <Button onClick={() => this.submitChanges()}>Opslaan</Button>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.plantNutritionalsReducer.isFetching,
      isError: state.plantNutritionalsReducer.isError,
      nutritional: state.plantNutritionalsReducer.nutritional,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
     getPlantNutritionalsAction : bindActionCreators(getPlantNutritionalsAction, dispatch),
     putPlantNutritionalsAction : bindActionCreators(putPlantNutritionalsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantNutritionals);
