import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Switch, InputNumber, DatePicker } from 'antd';
const { TextArea } = Input;

const NewPlantForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;

        return (
          <Modal
            style={{ top: 5 }}
            visible={visible}
            title="Maak een nieuwe plant aan"
            okText="OK"
            onCancel={onCancel}
            onOk={onCreate}>
            <Form layout="vertical">
              <Form.Item label="Naam">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Geef een naam op!' }],
              })(<Input />)}
              </Form.Item>
              <Form.Item label="Code">
                {getFieldDecorator('code', {
                  rules: [{ required: true, message: 'Geef een code op!' }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Beschrijving">
              {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Geef een Beschrijving op!' }],
              })(<TextArea rows={4} />)}
              </Form.Item>
              <Form.Item label="Actieve component">
              {getFieldDecorator('active_component', {
                rules: [{ required: true, message: 'Vul in!' }],
              })(<TextArea rows={4} />)}
              </Form.Item>
              <Form.Item label="Groeitijd">
                {getFieldDecorator('grow_time', {
                  rules: [{ required: true, message: 'Geef aantal dagen op!' }],
              })(<InputNumber min={1} max={365} />)}
              </Form.Item>
              <Form.Item label="Kruid?">
              {getFieldDecorator('herb', {
                rules: [{ required: true, message: 'Is het een kruid?' }],
            })(<InputNumber min={0} max={1} />)}
              </Form.Item>
              <Form.Item label="Van (Maand / Dag)">
                {getFieldDecorator('from', {
                  rules: [{ required: true, message: 'Geef een datum op' }],
              })(<Input placeholder="Maand / Dag"/>)}
              </Form.Item>
              <Form.Item label="Tot (Maand / Dag)">
                {getFieldDecorator('till', {
                  rules: [{ required: true, message: 'Geef een datum op' }],
              })(<Input placeholder="Maand / Dag"/>)}
              </Form.Item>
              <Form.Item label="Hoeveelheid">
                {getFieldDecorator('quantity', {
                  rules: [{ required: true, message: 'Geef een hoeveelheid op!' }],
              })(<InputNumber min={1} max={365} />)}
              </Form.Item>
              <Form.Item label="Opbrengst">
                {getFieldDecorator('yield', {
                  rules: [{ required: true, message: 'Geef de opbrengst op!' }],
              })(<InputNumber min={1} max={365} />)}
              </Form.Item>
              <Form.Item label="Dagen tussen oogsten">
                {getFieldDecorator('days_between_harvest', {
                  rules: [{ required: true, message: 'Geef een hoeveelheid op!' }],
              })(<InputNumber min={0} max={365} />)}
              </Form.Item>
              <Form.Item label="Aantal keer oogsten">
                {getFieldDecorator('amount_harvestable', {
                  rules: [{ required: true, message: 'Geef een hoeveelheid op!' }],
              })(<InputNumber min={1} max={365} />)}
              </Form.Item>
              <Form.Item label="Aantal credits">
                {getFieldDecorator('cost', {
                  rules: [{ required: true, message: 'Geef een op hoeveel credits het kost!' }],
              })(<InputNumber min={1} max={365} />)}
              </Form.Item>
            </Form>
          </Modal>
        );
    }
  }
);

export default NewPlantForm;
