import { Table } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getShopOrders } from "../../actions/shopOrdersAction";

class ShopOrdersOverview extends React.Component {
  componentDidMount() {
    this.props.getShopOrders();
  }

  state = {
    params: {},
    visible: false,
    searchText: "",
    searchedColumn: "",
  };

  render() {
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Gebruiker",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Prijs",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Levermethode",
        dataIndex: "delivery_method",
        key: "delivery_method",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    ];

    return (
      <div>
        <h1>Alle Shop orders</h1>

        <Table
          rowKey="id"
          bordered={true}
          loading={this.props.isFetching}
          dataSource={this.props.items}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                window.location = "/shop-orders/" + record.id;
              },
            };
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.shopOrderReducer.isFetching,
    isError: state.shopOrderReducer.isError,
    items: state.shopOrderReducer.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getShopOrders: bindActionCreators(getShopOrders, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopOrdersOverview);
