import { Table } from "antd";
import { Link } from "react-router-dom"
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCreditVouchersAction } from "./../../../actions/creditVoucherAction";
// import NewCampaignForm from './forms/NewPlant'

class CreditVoucherTable extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id
    };

    this.props.getCreditVouchersAction(params);
  }

  render() {
    let params = {
      id: this.props.match.params.id
    };

    if (this.props.isPosting && !this.props.isFetching) {
      this.props.getCreditVouchersAction(params);
    }

    const columns = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code"
      },
      {
        title: "Credits",
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: "Gebruikt",
        dataIndex: "used",
        key: "used"
      },
      {
        title: 'Gebruikt door',
        key: 'user_id',
        // width: 250,
        render: (text, record) => (
          <span>
            <Link to={`/users/${record.user_id}`} disabled={!record.user_id}>{record.name}</Link>
          </span>
        ),
     }
    ];

    return (
      <Table
        rowKey="id"
        bordered={true}
        loading={this.props.isFetching}
        dataSource={this.props.creditVouchers.data}
        columns={columns}
        pagination={{ pageSize: 15 }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.creditVoucherReducer.isFetching,
    isError: state.creditVoucherReducer.isError,
    creditVouchers: state.creditVoucherReducer.creditVouchers,
    isPosting: state.campaignReducer.isPosting,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCreditVouchersAction: bindActionCreators(getCreditVouchersAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditVoucherTable);
