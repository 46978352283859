import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getGardenInformationAction } from "../../actions/gardenInformationAction";

class GardenInformation extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id
    };

    this.props.getGardenInformationAction(params);
  }

  render() {
    return (
      <div>
        <p>
          {this.props.gardenInformation.row}.
          {this.props.gardenInformation.block}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.gardenInformationReducer.isFetching,
    isError: state.gardenInformationReducer.isError,
    gardenInformation: state.gardenInformationReducer.gardenInformation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGardenInformationAction: bindActionCreators(
      getGardenInformationAction,
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GardenInformation);
