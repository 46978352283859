import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Modal, Input, InputNumber } from "antd";
const { TextArea } = Input;

const NewShopItemForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          style={{ top: 5 }}
          visible={visible}
          title="Maak een nieuw shop item aan"
          okText="OK"
          onCancel={onCancel}
          onOk={onCreate}>
          <Form layout="vertical">
            <Form.Item label="Naam">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Geef een naam op!" }],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Beschrijving">
              {getFieldDecorator("description", {
                rules: [
                  { required: true, message: "Geef een Beschrijving op!" },
                ],
              })(<TextArea rows={4} />)}
            </Form.Item>
            <Form.Item label="Prijs">
              {getFieldDecorator("price", {
                rules: [{ required: true, message: "Geef een prijs op!" }],
              })(
                <InputNumber min={0} max={365} defaultValue="1" step="0.10" />
              )}
            </Form.Item>
            <Form.Item label="Aantal op voorraad">
              {getFieldDecorator("stock_amount", {
                rules: [
                  { required: true, message: "Geef een hoeveelheid op!" },
                ],
              })(<InputNumber min={0} max={365} />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default NewShopItemForm;
