import { Divider, Typography, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";

import PlantVars from "./subscreens/PlantVars";
import PlantNutritionals from "./subscreens/PlantNutritionals";
import PlantStars from "./subscreens/PlantStars";
import ActionsToPlantDetail from "./subscreens/ActionsToPlantDetail";
import PlantTimeline from "./subscreens/PlantTimeline";
import BusinessTimeline from "./subscreens/BusinessTimeline";
import DeletePlant from "./subscreens/DeletePlant";
import DemoPlantPhotos from "./subscreens/DemoPlantPhotos";
import PlantDiscount from "./subscreens/PlantDiscount";

const { TabPane } = Tabs;
const { Title } = Typography;

class PlantOverview extends React.Component {
  render() {
    return (
      <div>
        <Title>{this.props.currentPlant.name}</Title>
        <Divider />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Gegevens" key="1">
            <PlantVars {...this.props} />
          </TabPane>
          <TabPane tab="Voedingswaarden" key="2">
            <PlantNutritionals {...this.props} />
          </TabPane>
          <TabPane tab="Sterren" key="3">
            <PlantStars {...this.props} />
          </TabPane>
          <TabPane tab="Acties" key="4">
            <ActionsToPlantDetail {...this.props} />
          </TabPane>
          <TabPane tab="Actie Tijdlijn" key="5">
            <PlantTimeline {...this.props} />
          </TabPane>
          <TabPane tab="Business Tijdlijn" key="6">
            <BusinessTimeline {...this.props} />
          </TabPane>
          <TabPane tab="Korting & Tags" key="9">
            <PlantDiscount {...this.props} />
          </TabPane>
          {this.props.currentPlant.demo === 1 && (
            <TabPane tab="DemoPhotos" key="8">
              <DemoPlantPhotos {...this.props} />
            </TabPane>
          )}
          <TabPane tab="Uitschakelen" key="7">
            <DeletePlant {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentPlant: state.plantReducer.currentPlant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantOverview);
