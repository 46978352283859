import React, { Component } from "react";
// import './AppLayout.css'
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  AppstoreOutlined,
  DesktopOutlined,
  EuroOutlined,
  LogoutOutlined,
  PieChartOutlined,
  UserOutlined,
  ExportOutlined,
  InboxOutlined,
} from "@ant-design/icons";

// import Sidenav from '../../components/Sidenav/Sidenav';
// import Topnav from '../../components/Topnav/Topnav';
// import PageHeader from '../../components/PageHeader/PageHeader';
import { Layout, Menu } from "antd";

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class AppLayout extends Component {
  state = {
    collapsed: true,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}>
          <div className="logo" style={{ minHeight: "10px" }} />
          <Menu theme="dark" mode="inline">
            <Menu.Item key="1">
              <PieChartOutlined />
              <span>Home</span>
              <Link to="/"></Link>
            </Menu.Item>
            <Menu.Item key="2">
              <DesktopOutlined />
              <span>Veld</span>
              <Link to="/field"></Link>
            </Menu.Item>
            <Menu.Item key="55">
              <UserOutlined />
              <span>Klanten</span>
              <Link to="/users"></Link>
            </Menu.Item>
            <SubMenu
              key="sub2"
              title={
                <span>
                  <AppstoreOutlined />
                </span>
              }>
              <Menu.Item key="7">
                <Link to="/plants">Planten</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/actions">Acties</Link>
              </Menu.Item>
              <Menu.Item key="12">
                <Link to="/campaigns">Campagnes</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="14">
              <EuroOutlined />
              <span>Facturen</span>
              <Link to="/invoices"></Link>
            </Menu.Item>
            <Menu.Item key="64">
              <InboxOutlined />
              <span>Shop Items</span>
              <Link to="/shop-items"></Link>
            </Menu.Item>
            <Menu.Item key="65">
              <InboxOutlined />
              <span>Shop orders</span>
              <Link to="/shop-orders"></Link>
            </Menu.Item>
            <Menu.Item key="15">
              <ExportOutlined />
              <span>Exporteren</span>
              <Link to="/export"></Link>
            </Menu.Item>
            <Menu.Item key="10">
              <LogoutOutlined />
              <span>Uitloggen</span>
              <Link to="/logout"></Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ margin: "16px" }}>
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              {/* }{children} */}
              {this.props.component}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Mijn Boerderijtje ©2020 Created by ZekerZichtbaar & MeesMaas
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);

// import React from 'react';
// import {
//   Link
// } from "react-router-dom";
// import { Layout, Menu, Icon } from 'antd';
//
// const { Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;
//
// // class AppLayout {
// //
// //     render(){
// //         return (
// //
// //             <Layout style={{ minHeight: '100vh' }}>
// //               <Sider>
// //                 <div className="logo" style={{ minHeight: '10px'}}/>
// //                 <Menu theme="dark" mode="inline">
// //                   <Menu.Item key="1">
// //                     <Icon type="pie-chart" />
// //                     <span>Home</span>
// //                     <Link to="/">Home</Link>
// //                   </Menu.Item>
// //                   <Menu.Item key="2">
// //                     <Icon type="desktop" />
// //                     <span>Veld</span>
// //                     <Link to="/field">Veld</Link>
// //                   </Menu.Item>
// //                   <SubMenu
// //                     key="sub1"
// //                     title={
// //                       <span>
// //                         <Icon type="user" />
// //                         <span>Klanten</span>
// //                       </span>}>
// //                     <Menu.Item key="3"><Link to="/users">Overzicht</Link>Overzicht</Menu.Item>
// //                     <Menu.Item key="4">...</Menu.Item>
// //                     <Menu.Item key="5">...</Menu.Item>
// //                   </SubMenu>
// //                   <SubMenu
// //                     key="sub2"
// //                     title={
// //                       <span>
// //                         <Icon type="appstore" />
// //                         <span>Items</span>
// //                       </span>}>
// //                     <Menu.Item key="7"><Link to="/plants">Planten</Link>Planten</Menu.Item>
// //                     <Menu.Item key="9">Acties</Menu.Item>
// //                   </SubMenu>
// //                   <Menu.Item key="10">
// //                     <Icon type="logout" />
// //                     <span>Uitloggen</span>
// //                     <Link to="/logout">Uitloggen</Link>
// //                   </Menu.Item>
// //                 </Menu>
// //               </Sider>
// //               <Layout>
// //                 <Content style={{ margin: '16px' }}>
// //                   <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
// //                    {/* }{children} */}
// //                   </div>
// //                 </Content>
// //                 <Footer style={{ textAlign: 'center' }}>Mijn Boerderijtje ©2020 Created by ZekerZichtbaar & MeesMaas</Footer>
// //               </Layout>
// //             </Layout>
// //           )
// //     }
// //
// // }
// //
// // export default AppLayout;
//
// const AppLayout = ({children, ...rest}) => {
//   return (
//
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider>
//         <div className="logo" style={{ minHeight: '10px'}}/>
//         <Menu theme="dark" mode="inline">
//           <Menu.Item key="1">
//             <Icon type="pie-chart" />
//             <span>Home</span>
//             <Link to="/">Home</Link>
//           </Menu.Item>
//           <Menu.Item key="2">
//             <Icon type="desktop" />
//             <span>Veld</span>
//             <Link to="/field">Veld</Link>
//           </Menu.Item>
//           <SubMenu
//             key="sub1"
//             title={
//               <span>
//                 <Icon type="user" />
//                 <span>Klanten</span>
//               </span>}>
//             <Menu.Item key="3"><Link to="/users">Overzicht</Link>Overzicht</Menu.Item>
//             <Menu.Item key="4">...</Menu.Item>
//             <Menu.Item key="5">...</Menu.Item>
//           </SubMenu>
//           <SubMenu
//             key="sub2"
//             title={
//               <span>
//                 <Icon type="appstore" />
//                 <span>Items</span>
//               </span>}>
//             <Menu.Item key="7"><Link to="/plants">Planten</Link>Planten</Menu.Item>
//             <Menu.Item key="9"><Link to="/actions">Acties</Link>Acties</Menu.Item>
//           </SubMenu>
//           <Menu.Item key="10">
//             <Icon type="logout" />
//             <span>Uitloggen</span>
//             <Link to="/logout">Uitloggen</Link>
//           </Menu.Item>
//         </Menu>
//       </Sider>
//       <Layout>
//         <Content style={{ margin: '16px' }}>
//           <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
//           {children}
//           </div>
//         </Content>
//         <Footer style={{ textAlign: 'center' }}>Mijn Boerderijtje ©2020 Created by ZekerZichtbaar & MeesMaas</Footer>
//       </Layout>
//     </Layout>
//   )
// }
//
// export default AppLayout;
