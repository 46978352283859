import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, DatePicker, InputNumber } from "antd";
const { TextArea } = Input;

const NewGeneralVoucherForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Maak een nieuwe voucher aan"
          okText="OK"
          onCancel={onCancel}
          onOk={onCreate}>
          <Form layout="vertical">
            <Form.Item label="Naam">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Geef een naam op!" }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Beschrijving">
              {getFieldDecorator("description", {
                rules: [
                  { required: true, message: "Geef een Beschrijving op!" },
                ],
              })(<TextArea rows={4} />)}
            </Form.Item>
            <Form.Item label="Code">
              {getFieldDecorator("code", {
                rules: [
                  { required: true, message: "Geef een Beschrijving op!" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Aantal credits">
              {getFieldDecorator("credits", {
                rules: [
                  { required: true, message: "Geef een aantal op!" },
                ],
              })(<InputNumber />)}
            </Form.Item>
            <Form.Item label="Vanaf">
              {getFieldDecorator("from", {
                rules: [
                  { required: true, message: "Selecteer een vanaf datum!" },
                ],
              })(<DatePicker format='YYYY-MM-DD' />)}
            </Form.Item>
            <Form.Item label="Tot">
              {getFieldDecorator("till", {
                rules: [
                  { required: true, message: "Selecteer een tot datum!" },
                ],
              })(<DatePicker format='YYYY-MM-DD'/>)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

export default NewGeneralVoucherForm;
