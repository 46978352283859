import { Table } from 'antd';
import React from 'react';
import { Link } from "react-router-dom"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getInvoices } from '../../actions/invoiceAction';

class InvoiceOverview extends React.Component {
  componentDidMount(){
    this.props.getInvoices()
    this.setState({jwt_token: localStorage.getItem('authToken')});
  }

  state = {
      jwt_token: ''
  }

  render(){
    const columns = [
      {
        title: 'Nummer',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Bedrag',
        dataIndex: 'money',
        key: 'money',
      },
      {
        title: 'Datum',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Gebruiker',
        dataIndex: 'user',
        key: 'user',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <a href={record.url} target="_blank" rel="noopener noreferrer">bekijk</a>
          </span>
        ),
     }
    ];

    return (
      <div>
        <h1>Alle Facturen</h1>
        <Table rowKey="id" loading={this.props.isFetching} bordered={true} dataSource={this.props.invoices} columns={columns} pagination={{ pageSize: 20 }}/>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.invoiceReducer.isFetching,
        isError: state.invoiceReducer.isError,
        invoices: state.invoiceReducer.invoices,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
     getInvoices : bindActionCreators(getInvoices, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceOverview);
