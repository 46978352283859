import { Typography, Button } from 'antd';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deletePlantAction } from '../../../actions/plantAction';

const { Title } = Typography;

class DeletePlant extends React.Component {

  deletePlant = () => {
      let params = {
          id : this.props.match.params.id
      }

      this.props.deletePlantAction(params);
      setTimeout(() => {
          window.location = "/plants";
      }, 1000);
  }

  render(){
    return(
      <div>
      <Title level={4}>Uitschakelen</Title>
      <p>Pas op! De plant zal niet meer te zien zijn in de lijst</p>
      <br/>
      <Button onClick={() => this.deletePlant()} disabled={this.props.isDeleting} type="danger">Uitschakelen</Button>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
    return {
        isDeleting: state.plantReducer.isDeleting,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
      deletePlantAction : bindActionCreators(deletePlantAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePlant);
