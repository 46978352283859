import { Table, Button } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AlterCreditsForm from "./../forms/AlterCredits";
import { getCreditTransactionsAction } from "../../../actions/creditTransactionsAction";
import { alterUserCreditsAction } from "../../../actions/userAction";

class CreditTransactions extends React.Component {
  componentDidMount() {
    let params = {
      user_id: this.props.match.params.id,
    };

    this.props.getCreditTransactionsAction(params);
  }

  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      values["id"] = this.props.match.params.id;
      form.resetFields();
      this.props.alterUserCreditsAction(values);
      setTimeout(() => {
        this.props.getCreditTransactionsAction({
          user_id: this.props.match.params.id,
        });
      }, 1000);
      this.setState({ visible: false });
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    const columns = [
      {
        title: "Credits",
        dataIndex: "credits",
        key: "credits",
      },
      {
        title: "Wat?",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Afname?",
        dataIndex: "decrease",
        key: "descrease",
      },
      {
        title: "Datum",
        dataIndex: "created_at",
        key: "created_at",
      },
    ];

    return (
      <div>
        <Button
          type="primary"
          style={{ marginBottom: 12, marginLeft: 12 }}
          onClick={this.showModal}>
          Voeg credits toe
        </Button>
        <AlterCreditsForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          {...this.props}
        />
        <Table
          rowKey="id"
          loading={this.props.isFetching}
          bordered={true}
          dataSource={this.props.creditTransactions}
          columns={columns}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.creditTransactionsReducer.isFetching,
    isError: state.creditTransactionsReducer.isError,
    creditTransactions: state.creditTransactionsReducer.creditTransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCreditTransactionsAction: bindActionCreators(
      getCreditTransactionsAction,
      dispatch
    ),
    alterUserCreditsAction: bindActionCreators(
      alterUserCreditsAction,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditTransactions);
