import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginAction, getLoginUserAction } from '../../actions/authAction';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Col, Row, Input, Button, Alert, Typography } from 'antd';

class Login extends Component {
  state = {
    loading: false,
    loginError: false,
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      console.log(values);
      if (!err) {
        this.props.loginAction(values).then((loggedIn) => {
          if(loggedIn){
            // this.props.getLoginUserAction().then(this.props.history.push('/'))
            // console.log(this.props.from);
            this.props.history.push('/');
          } else {
            this.setState({loginError: true})
          }
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Title } = Typography;
    const { Paragraph } = Typography;
    return (
      <div className="loginScreen" style={{ maxHeight: '100vh', overflow: 'hidden', width: '100%' }}>
        <Layout style={{ backgroundColor: 'white' }}>
          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

            <Col xs={24} sm={24} md={14} lg={16} xl={14} xxl={14} type="flex" align="middle">
              <div style={{ maxWidth: '400px' }}>
                <Form onSubmit={this.handleSubmit} className="login-form" style={{ width: '100%', textAlign: 'left', }}>
                  {this.state.loginError ? (
                    <Alert style={{marginBottom: 10}} message="Inloggen mislukt" type="error" description="Email en/of wachtwoord onjuist" closable afterClose={() => this.setState({loginError: false})} />
                  ) : null}
                  <Title style={{ fontSize: '36px'}}>Inloggen</Title>
                  <Paragraph>Log in met een email en wachtwoord.</Paragraph>
                  <Form.Item style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ],
                    })(
                      <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    )}
                  </Form.Item>
                  <Form.Item style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Vul een wachtwoord in' }],
                    })(
                      <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Wachtwoord" />
                    )}
                  </Form.Item>
                  <Button style={{ width: '100%', margin: '16px 0 0 0' }} type="primary" htmlType="submit" className="login-form-button" loading={this.props.isFetching}>
                    Inloggen
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Layout>
      </div>
    );
  }
}
const LoginForm = Form.create({ name: 'normal_login' })(Login);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.authReducer.isFetching,
    isError : state.authReducer.isError
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    loginAction : bindActionCreators(loginAction, dispatch),
    getLoginUserAction : bindActionCreators(getLoginUserAction, dispatch)
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
