import React, { Component } from "react";
import { Button, Col, Row } from "antd";

export default class ExportScreen extends Component {
  render() {
    // let api = "http://localhost:8000/api/";
    let api = "https://api.mijnboerderijtje.nl/api/";

    return (
      <Row>
        <Col xs={24} md={6}>
          <h1>Acties</h1>
          <div>
            <Button
              type="primary"
              href={
                api +
                "export/action?id=5&name=Water&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Water
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/action?id=4&name=Ongedierte&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Ongedierte bestrijden
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/action?id=2&name=Harken&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Harken
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/action?id=1&name=Foto's&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Foto maken
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/action?id=6&name=Oogsten&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Oogsten
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/action?id=3&name=Bemesten&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Bemesten
            </Button>
            {/* <Button disabled type="primary" href="/" block>
            Oogsten (niet betaald)
            </Button> */}
          </div>
        </Col>
        <Col xs={24} md={1} />
        <Col xs={24} md={6}>
          <h1>Order Status</h1>
          <div>
            <Button
              type="primary"
              href={
                api +
                "export/orderstatus?order_status=0&name=Bestellen&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              Bestellen
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/orderstatus?order_status=1&name=Besteld&token=" +
                localStorage.authToken
              }
              style={{ marginBottom: 5 }}
              block>
              In bestelling
            </Button>
            <Button
              type="primary"
              href={
                api +
                "export/orderstatus?order_status=2&name=Zaaien&token=" +
                localStorage.authToken
              }
              block>
              Daadwerkelijk zaaien
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}
