import api from "../utils/api";

export const getPhotos = (params) => (dispatch) => {
  dispatch({
    type: "PHOTO_ACTION_FETCH",
  });
  return api("get", "/api/lots/photos", params, true)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "PHOTO_FETCH_ACTION_SUCCESS",
          payload: response.data.data,
        });
        console.log("successvol uploaden");
        return true;
      } else {
        dispatch({
          type: "PHOTO_ACTION_ERROR",
        });
        return false;
      }
    })
    .catch((e) => {
      dispatch({
        type: "PHOTO_ACTION_ERROR",
      });
      return false;
    });
};

export const postPhoto = (params) => (dispatch) => {
  dispatch({
    type: "PHOTO_ACTION_POST",
  });
  return api("post", "/api/lots/photos", params, true)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "PHOTO_POST_ACTION_SUCCESS",
        });
        console.log("successvol uploaden");
        return true;
      } else {
        dispatch({
          type: "PHOTO_ACTION_ERROR",
        });
        return false;
      }
    })
    .catch((e) => {
      dispatch({
        type: "PHOTO_ACTION_ERROR",
      });
      return false;
    });
};
