import { Table } from "antd";
import { Link } from "react-router-dom"
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getVouchersAction } from "./../../../actions/voucherAction";
// import NewCampaignForm from './forms/NewPlant'

class VoucherTable extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id
    };

    this.props.getVouchersAction(params);
  }

  render() {
    let params = {
      id: this.props.match.params.id
    };

    if (this.props.isPosting && !this.props.isFetching) {
      this.props.getVouchersAction(params);
    }

    const columns = [

      {
        title: "Code",
        dataIndex: "code",
        key: "code"
      },
      {
        title: "Gebruikt",
        dataIndex: "used",
        key: "used"
      },
      {
        title: 'Gebruikt door',
        key: 'user_id',
        // width: 250,
        render: (text, record) => (
          <span>
            <Link to={`/users/${record.user_id}`} disabled={!record.user_id}>{record.name}</Link>
          </span>
        ),
     }
    ];

    return (
      <Table
        rowKey="id"
        bordered={true}
        loading={this.props.isFetching}
        dataSource={this.props.vouchers.data}
        columns={columns}
        pagination={{ pageSize: 15 }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.voucherReducer.isFetching,
    isError: state.voucherReducer.isError,
    isPosting: state.campaignReducer.isPosting,
    vouchers: state.voucherReducer.vouchers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVouchersAction: bindActionCreators(getVouchersAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTable);
