import api from '../utils/api'

export const getGardenAction = (params) => dispatch => {
  dispatch({
    type: 'GARDEN_ACTION_FETCH'
  })
  return api('get','/api/gardens/lots', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'GARDEN_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'GARDEN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'GARDEN_ACTION_ERROR',
    })
    return false;
  });
}

export const updateWaterGardenAction = (params) => dispatch => {
  dispatch({
    type: 'GARDEN_ACTION_PUT'
  })
  return api('put','/api/gardens/status', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'GARDEN_ACTION_PUT_SUCCESS',
        // payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'GARDEN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'GARDEN_ACTION_ERROR',
    })
    return false;
  });
}

export const updateWaterGardenActionPositive = (params) => dispatch => {
  dispatch({
    type: 'GARDEN_ACTION_PUT'
  })
  return api('put','/api/gardens/statusAdmin', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'GARDEN_ACTION_PUT_SUCCESS',
        // payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'GARDEN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'GARDEN_ACTION_ERROR',
    })
    return false;
  });
}
