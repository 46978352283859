import api from '../utils/api'

export const getActionsToPlantAction = (params) => dispatch => {
  dispatch({
    type: 'ACTION_TO_PLANT_ACTION_FETCH'
  })
  return api('get','/api/actions/plants', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'ACTION_TO_PLANT_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'ACTION_TO_PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'ACTION_TO_PLANT_ACTION_ERROR',
    })
    return false;
  });
}

export const postActionsToPlantAction = (params) => dispatch => {
  dispatch({
    type: 'ACTION_TO_PLANT_ACTION_POST'
  })
  return api('post','/api/actions/plants', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'ACTION_TO_PLANT_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'ACTION_TO_PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'ACTION_TO_PLANT_ACTION_ERROR',
    })
    return false;
  });
}

export const putActionsToPlantAction = (params) => dispatch => {
  dispatch({
    type: 'ACTION_TO_PLANT_ACTION_PUT'
  })
  return api('put','/api/actions/plants', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'ACTION_TO_PLANT_PUT_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'ACTION_TO_PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'ACTION_TO_PLANT_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteActionsToPlantAction = (params) => dispatch => {
  dispatch({
    type: 'ACTION_TO_PLANT_ACTION_DELETE'
  })
  return api('delete','/api/actions/plants', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'ACTION_TO_PLANT_DELETE_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'ACTION_TO_PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'ACTION_TO_PLANT_ACTION_ERROR',
    })
    return false;
  });
}
