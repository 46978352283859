export default (state = {isFetching: false, isError: false, isPosting: false, isSuccess: false, photos: [] }, action) => {
    switch (action.type) {
      case 'PHOTO_ACTION_FETCH':
      return {
          ...state,
          isFetching: true,
      }
      case 'PHOTO_FETCH_ACTION_SUCCESS':
      return {
          ...state,
          isFetching: false,
          photos: action.payload
      }
      case 'PHOTO_ACTION_POST':
      return {
          ...state,
          isPosting: true,
          isError: false,
          isSuccess: false
      }
      case 'PHOTO_POST_ACTION_SUCCESS':
      return {
          ...state,
          isPosting: false,
          isError: false,
          isSuccess: true
      }
      case 'PHOTO_ACTION_ERROR':
      return {
          ...state,
          isError: true,
          isPosting: false,
          isSuccess: false
      }
      default:
          return state
      }
}
