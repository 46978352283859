export default (
  state = { isError: false, isFetching: false, isPosting: false, items: [] },
  action
) => {
  switch (action.type) {
    case "SHOP_ORDERS_ACTION_FETCH":
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case "SHOP_ORDERS_ACTION_POST":
      return {
        ...state,
        isPosting: true,
        isError: false,
      };
    case "SHOP_ORDERS_ACTION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isError: false,
        items: action.payload,
      };
    case "SHOP_ORDERS_ACTION_POST_SUCCESS":
      return {
        ...state,
        isPosting: false,
        isError: false,
        isFetching: false,
      };
    case "SHOP_ORDERS_ACTION_ERROR":
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
