import { Table, Divider, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getCampaignsAction,
  postCampaignAction,
} from "../../actions/campaignAction";
import NewCampaignForm from "./forms/NewCampaign";

class CampaignOverview extends React.Component {
  componentDidMount() {
    this.props.getCampaignsAction();
  }

  state = {
    params: {},
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postCampaignAction(values);
      this.props.getCampaignsAction();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        // width: 300,
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Vouchers",
        dataIndex: "total_vouchers",
        key: "total_vouchers",
      },
    ];

    return (
      <div>
        <h1>Alle Campagnes</h1>
        <Button
          type="primary"
          style={{ marginBottom: 12 }}
          onClick={this.showModal}>
          Voeg Toe
        </Button>
        <Table
          rowKey="id"
          bordered={true}
          loading={this.props.isFetching}
          dataSource={this.props.campaigns.data}
          columns={columns}
          pagination={{ pageSize: 15 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                window.location = "/campaign/" + record.id;
              }, // click row
              onDoubleClick: event => {}, // double click row
              onContextMenu: event => {}, // right button click row
              onMouseEnter: event => {}, // mouse enter row
              onMouseLeave: event => {}, // mouse leave row
            };
          }}
        />
        <NewCampaignForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.campaignReducer.isFetching,
    isError: state.campaignReducer.isError,
    campaigns: state.campaignReducer.campaigns,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCampaignsAction: bindActionCreators(getCampaignsAction, dispatch),
    postCampaignAction: bindActionCreators(postCampaignAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignOverview);
