import api from '../utils/api'

export const putOrderStatus = (params) => dispatch => {
  dispatch({
    type: 'ORDER_STATUS_ACTION_PUT'
  })
  return api('put','/api/lots/orderStatus', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'ORDER_STATUS_ACTION_PUT_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'ORDER_STATUS_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'ORDER_STATUS_ACTION_ERROR',
    })
    return false;
  });
}
