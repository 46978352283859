export default (
  state = { isError: false, isPutting: false, isFetching: false, users: [] },
  action
) => {
  switch (action.type) {
    case "USER_ACTION_FETCH":
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case "USER_ACTION_PUT":
      return {
        ...state,
        isPutting: true,
        isError: false,
      };
    case "USER_ACTION_SUCCESS":
      return {
        ...state,
        isFetching: false,
        isError: false,
        isPutting: false,
        users: action.payload,
      };
    case "USER_ACTION_ERROR":
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
