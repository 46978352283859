import {
  Timeline,
  Divider,
  Button,
  Modal,
  notification,
  Popconfirm,
  Spin
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getPlantTimelineAction,
  updatePlantTimelineAction,
  postPlantTimelineAction,
  deletePlantTimelineAction
} from "../../../actions/plantTimelineAction";
import NewPlantTimelineForm from "../forms/NewPlantTimeline";
import UpdatePlantTimelineForm from "../forms/UpdatePlantTimeline";

const openNotificationWithIcon = type => {
  notification[type]({
    message: "Actie uitgevoerd",
    description: "De tijdlijn entry is met succes toegevoegd!"
  });
};

class PlantTimeline extends React.Component {
  componentDidMount() {
    let params = {
      plant_id: this.props.match.params.id
    };

    this.props.getPlantTimelineAction(params);
    this.setState({ params: params });
  }

  componentDidUpdate() {
    if (this.props.isSuccess) {
      this.props.getPlantTimelineAction(this.state.params);
    }
  }

  state = {
    params: {},
    numero: 0,
    visible: false,
    updateVisible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  showUpdateModal = (key) => {
    console.log(key);
    this.setState({ updateVisible: true, numero: key});
  };

  handleUpdateCancel = () => {
    this.setState({ updateVisible: false });
  };

  handleUpdateCreate = () => {
    const { form } = this.formUpdateRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({ updateVisible: false });
      console.log(values);

      form.resetFields();
      this.props.updatePlantTimelineAction(values);
      setTimeout(() => {
          this.props.getPlantTimelineAction(this.state.params);
        }, 1000);
      // values["plant_id"] = this.state.params.plant_id;
      // console.log("Received values of form: ", values);
      // this.setState({ visible: false });
      // openNotificationWithIcon("success");
    });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values["plant_id"] = this.state.params.plant_id;
      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postPlantTimelineAction(values);
      this.setState({ visible: false });
      openNotificationWithIcon("success");
      setTimeout(() => {
        this.props.getPlantTimelineAction(this.state.params);
      }, 1000);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  saveFormUpdateRef = formRef => {
    this.formUpdateRef = formRef;
  };

  confirmDelete = id => {
    let params = {
      id: id
    };
    this.props.deletePlantTimelineAction(params);
  };

  render() {
    if (this.props.isFetching) {
      return (
        <div>
          <h1>Planten Tijdlijn</h1>
          <Spin />
        </div>
      );
    } else if (this.props.isError) {
      return <div>Error...</div>;
    } else {
      const plantTimelineItems = this.props.plantTimeline.map((item, key) => {
        return (
          <Timeline.Item key={key}>
            <p>Dag: {item.day}</p>
            <p>Actie: {item.name}</p>
            <p>Titel: {item.title}</p>
            <p>Message: {item.message}</p>
            <span>
              <Link onClick={() => this.showUpdateModal(key)}>
                Aanpassen
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() => this.confirmDelete(item.id)}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">Verwijderen</a>
              </Popconfirm>
            </span>
          </Timeline.Item>
        );
      });

      return (
        <div>
          <h1>Planten Tijdlijn</h1>
          <Button type="primary" onClick={this.showModal}>
            Voeg Toe
          </Button>
          <br></br>
          <br></br>
          <Timeline>{plantTimelineItems}</Timeline>
          <NewPlantTimelineForm
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            onCreate={this.handleCreate}
            {...this.props}
          />

          <UpdatePlantTimelineForm
            wrappedComponentRef={this.saveFormUpdateRef}
            visible={this.state.updateVisible}
            onCancel={this.handleUpdateCancel}
            onCreate={this.handleUpdateCreate}
            numero={this.state.numero}
            {...this.props}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.plantTimelineReducer.isFetching,
    isPosting: state.plantTimelineReducer.isPosting,
    isError: state.plantTimelineReducer.isError,
    isDeleting: state.plantTimelineReducer.isDeleting,
    isSuccess: state.plantTimelineReducer.isSuccess,
    plantTimeline: state.plantTimelineReducer.timeline
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPlantTimelineAction: bindActionCreators(
      getPlantTimelineAction,
      dispatch
    ),
    postPlantTimelineAction: bindActionCreators(
      postPlantTimelineAction,
      dispatch
    ),
    updatePlantTimelineAction: bindActionCreators(
      updatePlantTimelineAction,
      dispatch
    ),
    deletePlantTimelineAction: bindActionCreators(
      deletePlantTimelineAction,
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantTimeline);
