import { Upload, Button, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { postPhoto, getPhotos } from "../../../actions/lotPhotoAction";

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "Upload geslaagd",
    description: "De foto is geupload",
  });
};

class Photo extends React.Component {
  componentDidMount() {
    this.props.getPhotos({ lot_id: this.props.match.params.id });
  }

  state = {
    fileList: [],
    updated: false,
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();

    formData.append("lot_id", this.props.match.params.id);
    formData.append("file", fileList[0]);

    console.log(fileList);

    this.props.postPhoto(formData);

    this.setState({
      updated: false,
      fileList: [],
    });
  };

  componentDidUpdate() {
    if (this.props.isSuccess && !this.state.updated) {
      openNotificationWithIcon("success");
      this.props.getPhotos({ lot_id: this.props.match.params.id });
      this.setState({
        updated: true,
      });
    }
  }

  render() {
    const { fileList } = this.state;
    const props = {
      multiple: false,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const content = this.props.photos.map((item, key) => {
      return <img key={key} src={item.url} width={"100%"} alt="Logo" />;
    });

    return (
      <div>
        <Upload {...props} accept=".jpg,.png">
          <Button>
            <UploadOutlined /> Select File
          </Button>
        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={this.props.isPosting}
          style={{ marginTop: 16, marginBottom: 16 }}>
          {this.props.isPosting ? "Uploading" : "Start Upload"}
        </Button>

        <h1>Onderstaande foto's zijn al geupload</h1>

        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isPosting: state.lotPhotoReducer.isPosting,
    isSuccess: state.lotPhotoReducer.isSuccess,
    isError: state.lotPhotoReducer.isError,
    photos: state.lotPhotoReducer.photos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postPhoto: bindActionCreators(postPhoto, dispatch),
    getPhotos: bindActionCreators(getPhotos, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Photo);
