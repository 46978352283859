import { Table, Divider, Button, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPlantsAction, postPlantAction } from "../../actions/plantAction";
import NewPlantForm from "./forms/NewPlant";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

class PlantOverview extends React.Component {
  componentDidMount() {
    this.props.getPlantsAction();
  }

  state = {
    params: {},
    visible: false,
    searchText: "",
    searchedColumn: "",
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postPlantAction(values);
      this.props.getPlantsAction();
      this.setState({ visible: false });
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: "20%",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        ...this.getColumnSearchProps("code"),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        ...this.getColumnSearchProps("description"),
      },
    ];

    return (
      <div>
        <h1>Alle Planten</h1>
        <Button
          type="primary"
          style={{ marginBottom: 12 }}
          onClick={this.showModal}>
          Voeg Toe
        </Button>
        <Table
          rowKey="id"
          bordered={true}
          loading={this.props.isFetching}
          dataSource={this.props.plants.data}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                window.location = "/plants/" + record.id;
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
        />
        <NewPlantForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.plantReducer.isFetching,
    isError: state.plantReducer.isError,
    plants: state.plantReducer.plants,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlantsAction: bindActionCreators(getPlantsAction, dispatch),
    postPlantAction: bindActionCreators(postPlantAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantOverview);
