import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Modal, Input, InputNumber, DatePicker, Select, Tag } from "antd";
import { getExActionsAction } from "../../../actions/exActionAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Option } = Select;

const NewPlantDiscount = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      if (this.props.isFetching) {
        return <div>Loading...</div>;
      } else if (this.props.isError) {
        return <div>Error...</div>;
      } else {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;
        // const dateFormat = "YYYY-MM-DD";

        return (
          <Modal
            visible={visible}
            title="Maak een nieuwe entry aan"
            okText="OK"
            onCancel={onCancel}
            onOk={onCreate}>
            <Form layout="vertical">
              <Form.Item label="Titel">
                {getFieldDecorator("title", {
                  rules: [{ required: true, message: "Geef een titel op!" }],
                })(<Input maxLength={11} />)}
              </Form.Item>

              <Form.Item label="Kleur">
                {getFieldDecorator("color", {
                  rules: [{ required: true, message: "Geef een kleur op!" }],
                })(
                  <Select>
                    <Option value="#1A84FF">
                      <Tag color={"#1A84FF"}>Blauw </Tag>
                    </Option>
                    <Option value="#8CC800">
                      <Tag color={"#8CC800"}>Groen </Tag>
                    </Option>
                    <Option value="#FFDA3A">
                      <Tag color={"#FFDA3A"}>Geel </Tag>
                    </Option>
                    <Option value="#FF6363">
                      <Tag color={"#FF6363"}>Rood </Tag>
                    </Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Start">
                {getFieldDecorator("from", {
                  rules: [{ required: true, message: "Geef een datum op!" }],
                })(<DatePicker format={"YYYY-MM-DD"} />)}
              </Form.Item>

              <Form.Item label="Einde">
                {getFieldDecorator("till", {
                  rules: [{ required: true, message: "Geef een datum op!" }],
                })(<DatePicker format={"YYYY-MM-DD"} />)}
              </Form.Item>

              <Form.Item label="Credits">
                {getFieldDecorator("credits", {
                  rules: [{ required: true, message: "Geef een aantal op!" }],
                })(<InputNumber />)}
              </Form.Item>
            </Form>
          </Modal>
        );
      }
    }
  }
);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.exActionReducer.isFetching,
    IsError: state.exActionReducer.isError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExActionsAction: bindActionCreators(getExActionsAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPlantDiscount);
