import api from '../utils/api'

export const getLotAction = (params) => dispatch => {
  dispatch({
    type: 'LOT_ACTION_FETCH'
  })
  return api('get','/api/lots', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'LOT_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'LOT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOT_ACTION_ERROR',
    })
    return false;
  });
}

export const putLotStatusAction = (params) => dispatch => {
  dispatch({
    type: 'LOT_ACTION_PUT'
  })
  return api('put','/api/lots/status', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'LOT_ACTION_PUT_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'LOT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOT_ACTION_ERROR',
    })
    return false;
  });
}

export const offsetAction = (params) => dispatch => {
  dispatch({
    type: 'LOT_ACTION_OFFSET_PUT'
  })
  return api('put','/api/lots/offset', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'LOT_ACTION_OFFSET_PUT_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'LOT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOT_ACTION_ERROR',
    })
    return false;
  });
}

export const deleteLotAction = (params) => dispatch => {
  dispatch({
    type: 'LOT_ACTION_DELETE'
  })
  return api('delete','/api/lots', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'LOT_ACTION_DELETE_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'LOT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOT_ACTION_ERROR',
    })
    return false;
  });
}
