import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Spin, Input } from 'antd';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActionsToPlantAction, putActionsToPlantAction } from '../../../actions/actionsToPlantAction';

const { Title } = Typography;

class ActionsToPlantDetail extends React.Component {

  componentDidMount(){
    let params = {
        plant_id : this.props.match.params.id
    }

    this.props.getActionsToPlantAction(params);
    this.handleInputChange.bind(this);

    // this.setState({params: params});
        // this.props.getPlantsAction()
  }

  handleInputChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.props.putActionsToPlantAction({id: name, cost: value});

      console.log(value);
      console.log(name);
  }

  render(){
    if (this.props.isFetching && !this.props.isError) {
      return (<Spin />)
    } else if (!this.props.isFetching && this.props.isError) {

    }

    const content = this.props.actionsToPlant.map((item, key) => {
        return (
            <Form.Item key={key} label={item.name} style={{marginBottom: 0}}>
              <Input type="number" name={item.id} defaultValue={item.cost} onChange={this.handleInputChange}/>
            </Form.Item>
        )
    });


    return(
      <div>
          <Title level={4}>Actie kosten</Title>
          <p>Deze waardes worden automatisch opgeslagen!</p>
          <p>Zet op 0 om een actie uit te zetten</p>
          <Form>
            {content}
          </Form>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.actionsToPlantReducer.isFetching,
      isError: state.actionsToPlantReducer.isError,
      actionsToPlant: state.actionsToPlantReducer.actionsToPlant,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
     getActionsToPlantAction : bindActionCreators(getActionsToPlantAction, dispatch),
     putActionsToPlantAction : bindActionCreators(putActionsToPlantAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsToPlantDetail);
