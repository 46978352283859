import api from "../utils/api";

export const getPlantDiscount = (plantId) => (dispatch) => {
  dispatch({
    type: "PLANT_DISCOUNT_ACTION_FETCH",
  });
  return api("get", `/api/plantdiscounts/${plantId}`, null, true)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "PLANT_DISCOUNT_ACTION_SUCCESS",
          payload: response.data.data,
        });
        return true;
      } else {
        dispatch({
          type: "PLANT_DISCOUNT_ACTION_ERROR",
        });
        return false;
      }
    })
    .catch((e) => {
      dispatch({
        type: "PLANT_DISCOUNT_ACTION_ERROR",
      });
      return false;
    });
};

export const postPlantDiscount = (params) => (dispatch) => {
  dispatch({
    type: "PLANT_DISCOUNT_ACTION_POST",
  });
  return api("post", "/api/plantdiscounts", params, true)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "PLANT_DISCOUNT_ACTION_POST_SUCCESS",
        });
        return true;
      } else {
        dispatch({
          type: "PLANT_DISCOUNT_ACTION_ERROR",
        });
        return false;
      }
    })
    .catch((e) => {
      dispatch({
        type: "PLANT_DISCOUNT_ACTION_ERROR",
      });
      return false;
    });
};

export const deletePlantDiscount = (plantDiscountId) => (dispatch) => {
  dispatch({
    type: "PLANT_DISCOUNT_ACTION_DELETE",
  });
  return api("delete", `/api/plantdiscounts/${plantDiscountId}`, null, true)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: "PLANT_DISCOUNT_DELETE_ACTION_SUCCESS",
        });
        return true;
      } else {
        dispatch({
          type: "PLANT_DISCOUNT_ACTION_ERROR",
        });
        return false;
      }
    })
    .catch((e) => {
      dispatch({
        type: "PLANT_DISCOUNT_ACTION_ERROR",
      });
      return false;
    });
};
