import {
  Timeline,
  Divider,
  Button,
  Spin,
  notification,
  Popconfirm,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getBusinessTimelineAction,
  postBusinessTimelineAction,
  deleteBusinessTimelineAction,
} from "../../../actions/businessTimelineAction";
import NewBusinessTimelineForm from "../forms/NewBusinessTimeline";

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "Actie uitgevoerd",
    description: "De tijdlijn entry is met succes toegevoegd!",
  });
};

class BusinessTimeline extends React.Component {
  componentDidMount() {
    let params = {
      plant_id: this.props.match.params.id,
    };

    this.setState({ params: params });

    this.props.getBusinessTimelineAction(params);
  }

  componentDidUpdate() {
    if (this.props.isSuccess) {
      this.props.getBusinessTimelineAction(this.state.params);
    }
  }

  state = {
    params: {},
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values["plant_id"] = this.state.params.plant_id;
      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postBusinessTimelineAction(values);
      this.setState({ visible: false });
      openNotificationWithIcon("success");
      setTimeout(() => {
        this.props.getBusinessTimelineAction(this.state.params);
      }, 1000);
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  confirmDelete = (id) => {
    let params = {
      id: id,
    };
    this.props.deleteBusinessTimelineAction(params);
  };

  render() {
    if (this.props.isFetching) {
      return (
        <div>
          <h1>Business Tijdlijn</h1>
          <Spin />
        </div>
      );
    } else if (this.props.isError) {
      return <div>Error...</div>;
    } else {
      const businessTimelineItems = this.props.businessTimeline.map(
        (item, key) => (
          <Timeline.Item key={key}>
            <p>Dag: {item.day}</p>
            <p>Titel: {item.title}</p>
            <p>Message: {item.message}</p>
            <p>
              {item.youtube_link && (
                <iframe
                  width="auto"
                  title="video"
                  height="auto"
                  src={`https://youtube.com/embed/${item.youtube_link}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
              )}
            </p>
            <span>
              <Link to={`/businessTimeline/edit/${item.id}`} disabled>
                Aanpassen
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() => this.confirmDelete(item.id)}
                okText="Yes"
                cancelText="No">
                <a href="#">Verwijderen</a>
              </Popconfirm>
            </span>
          </Timeline.Item>
        )
      );

      return (
        <div>
          <h1>Business Tijdlijn</h1>
          <Button type="primary" onClick={this.showModal}>
            Voeg Toe
          </Button>
          <br></br>
          <br></br>
          <Timeline>{businessTimelineItems}</Timeline>
          <NewBusinessTimelineForm
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            onCreate={this.handleCreate}
            {...this.props}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.businessTimelineReducer.isFetching,
    isError: state.businessTimelineReducer.isError,
    isPosting: state.businessTimelineReducer.isPosting,
    isDeleting: state.businessTimelineReducer.isDeleting,
    isSuccess: state.businessTimelineReducer.isSuccess,
    businessTimeline: state.businessTimelineReducer.timeline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessTimelineAction: bindActionCreators(
      getBusinessTimelineAction,
      dispatch
    ),
    postBusinessTimelineAction: bindActionCreators(
      postBusinessTimelineAction,
      dispatch
    ),
    deleteBusinessTimelineAction: bindActionCreators(
      deleteBusinessTimelineAction,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTimeline);
