import api from '../utils/api'

export const getGeneralVouchersAction = (params) => dispatch => {
  params = params || {};

  dispatch({
    type: 'GENERAL_VOUCHERS_ACTION_FETCH'
  })
  return api('get','/api/generalvouchers', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'GENERAL_VOUCHERS_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'GENERAL_VOUCHERS_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'GENERAL_VOUCHERS_ACTION_ERROR',
    })
    return false;
  });
}

export const postGeneralVouchersAction = (params) => dispatch => {
  dispatch({
    type: 'GENERAL_VOUCHERS_ACTION_POST'
  })
  return api('post','/api/generalvouchers', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'GENERAL_VOUCHERS_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'GENERAL_VOUCHERS_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'GENERAL_VOUCHERS_ACTION_ERROR',
    })
    return false;
  });
}
