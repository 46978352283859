import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Modal, Input, InputNumber } from "antd";
const { TextArea } = Input;

const AlterCreditsForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Schrijf credits bij"
          okText="OK"
          onCancel={onCancel}
          onOk={onCreate}>
          <Form layout="vertical">
            <Form.Item label="Credits">
              {getFieldDecorator("credits", {
                rules: [{ required: true, message: "Geef credits op!" }],
              })(<InputNumber min={1}  />)}
            </Form.Item>
            <Form.Item label="Reden">
              {getFieldDecorator("message", {
                rules: [{ required: true, message: "Geef een reden op!" }],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);


export default (AlterCreditsForm);
