import api from '../utils/api'

export const getFieldsAction = () => dispatch => {
  dispatch({
    type: 'FIELD_ACTION_FETCH'
  })
  return api('get','/api/fields', null, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'FIELD_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'FIELD_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'FIELD_ACTION_ERROR',
    })
    return false;
  });
}

export const postFieldsAction = (params) => dispatch => {
  dispatch({
    type: 'FIELD_ACTION_POST'
  })
  return api('post','/api/fields/addRow', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'FIELD_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'FIELD_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'FIELD_ACTION_ERROR',
    })
    return false;
  });
}
