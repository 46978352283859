/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import plantReducer from "./plantReducer";
import plantTimelineReducer from "./plantTimelineReducer";
import businessTimelineReducer from "./businessTimelineReducer";
import exActionReducer from "./exActionReducer";
import userReducer from "./userReducer";
import fieldReducer from "./fieldReducer";
import plantNutritionalsReducer from "./plantNutritionalsReducer";
import gardenReducer from "./gardenReducer";
import lotReducer from "./lotReducer";
import actionsExecutedReducer from "./actionsExecutedReducer";
import campaignReducer from "./campaignReducer";
import voucherReducer from "./voucherReducer";
import actionsToPlantReducer from "./actionsToPlantReducer";
import plantStarReducer from "./plantStarReducer";
import singleUserReducer from "./singleUserReducer";
import invoiceReducer from "./invoiceReducer";
import creditTransactionsReducer from "./creditTransactionsReducer";
import addressReducer from "./addressReducer";
import lotPhotoReducer from "./lotPhotoReducer";
import gardenInformationReducer from "./gardenInformationReducer";
import creditVoucherReducer from "./creditVoucherReducer";
import generalVoucherReducer from "./generalVoucherReducer";
import motdReducer from "./motdReducer";
import demoPlantPhotoReducer from "./demoPlantPhotoReducer";
import orderStatusReducer from "./orderStatusReducer";
import plantDiscountReducer from "./plantDiscountReducer";
import shopItemReducer from "./shopItemReducer";
import shopOrderReducer from "./shopOrderReducer";

export default combineReducers({
  authReducer,
  plantReducer,
  plantTimelineReducer,
  businessTimelineReducer,
  exActionReducer,
  userReducer,
  fieldReducer,
  plantNutritionalsReducer,
  gardenReducer,
  lotReducer,
  actionsExecutedReducer,
  campaignReducer,
  voucherReducer,
  actionsToPlantReducer,
  plantStarReducer,
  singleUserReducer,
  invoiceReducer,
  creditTransactionsReducer,
  addressReducer,
  lotPhotoReducer,
  gardenInformationReducer,
  creditVoucherReducer,
  generalVoucherReducer,
  motdReducer,
  demoPlantPhotoReducer,
  orderStatusReducer,
  plantDiscountReducer,
  shopItemReducer,
  shopOrderReducer,
});
