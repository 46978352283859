import { Table, Button } from "antd";
import { Link } from "react-router-dom"
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getGeneralVouchersAction,
  postGeneralVouchersAction,
} from "../../../actions/generalVoucherAction";
import NewGeneralVoucherForm from "./../forms/NewGeneralVoucher";

class GeneralVoucherOverview extends React.Component {
  componentDidMount() {
    let params = {
      campaign_id: this.props.match.params.id,
    };

    this.props.getGeneralVouchersAction(params);
  }

  state = {
    params: {},
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values["campaign_id"] = this.props.match.params.id;

      console.log("Received values of form: ", values);
      form.resetFields();
      this.props.postGeneralVouchersAction(values);
      this.props.getGeneralVouchersAction({
        campaign_id: this.props.match.params.id,
      });
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const expandedRowRender = () => {
      const columns = [
        {
          title: "Gebruikt door",
          key: "user_id",
          // width: 250,
          render: (text, record) => (
            <span>
              <Link to={`/users/${record.user_id}`} disabled={!record.user_id}>
                {record.name}
              </Link>
            </span>
          ),
        },
        { title: "Datum", dataIndex: "created_at", key: "created_at" },
      ];

      const data = this.props.generalvouchers[0].data;
      // for (let i = 0; i < 3; ++i) {
      //   data.push({
      //     key: i,
      //     date: '2014-12-24 23:12:00',
      //     name: 'This is production name',
      //     upgradeNum: 'Upgraded: 56',
      //   });
      // }
      return (
        <Table
          rowKey="user_id"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        // width: 300,
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Credits",
        dataIndex: "credits",
        key: "credits",
      },
      {
        title: "Vanaf",
        dataIndex: "from",
        key: "from",
      },
      {
        title: "Tot",
        dataIndex: "till",
        key: "till",
      },
    ];

    return (
      <div>
        <h1>Meerdere malen te gebruiken vouchers</h1>
        <Button
          type="primary"
          style={{ marginBottom: 12 }}
          onClick={this.showModal}>
          Voeg Toe
        </Button>
        <Table
          rowKey="id"
          bordered={true}
          loading={this.props.isFetching}
          dataSource={this.props.generalvouchers}
          columns={columns}
          pagination={{ pageSize: 15 }}
          expandable={{ expandedRowRender }}
        />
        <NewGeneralVoucherForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.generalVoucherReducer.isFetching,
    isError: state.generalVoucherReducer.isError,
    generalvouchers: state.generalVoucherReducer.generalvouchers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGeneralVouchersAction: bindActionCreators(
      getGeneralVouchersAction,
      dispatch
    ),
    postGeneralVouchersAction: bindActionCreators(
      postGeneralVouchersAction,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralVoucherOverview);
