import { Spin, List, Button, Input } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getMotd, postMotd } from "../../../actions/motdAction";

const { TextArea } = Input;

class ExecutedActions extends React.Component {
  componentDidMount() {
    this.props.getMotd();
  }

  state = {
    message: null,
  };

  newMotd = () => {
    this.props.postMotd(this.state);

    setTimeout(() => {
      this.props.getMotd();
    }, 1000);
  };

  onChange = e => {
    this.setState({ message: e.target.value });
  };

  render() {
    if (this.props.isFetching && !this.props.isError) {
      return <Spin />;
    }

    const content = this.props.motd.map((item, key) => {
      return (
        <List.Item key={key}>
          <List.Item.Meta
            title={item.message}
            description={"Datum: " + item.created_at}
          />
        </List.Item>
      );
    });

    return (
      <div>
        <h3 style={{ marginBottom: 16 }}>Bericht van de dag</h3>
        <TextArea
          style={{ marginBottom: 5 }}
          rows={4}
          onChange={this.onChange}
        />
        <Button type="primary" onClick={this.newMotd}>
          Stel in als nieuwe motd
        </Button>
        <List>{content}</List>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.motdReducer.isFetching,
    isError: state.motdReducer.isError,
    isPosting: state.motdReducer.isPosting,
    motd: state.motdReducer.motd,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMotd: bindActionCreators(getMotd, dispatch),
    postMotd: bindActionCreators(postMotd, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutedActions);
