import {
  Row,
  Col,
  Typography,
  Breadcrumb,
  Spin,
  Progress,
  Popconfirm,
  Card,
  Button,
  InputNumber,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getLotAction,
  putLotStatusAction,
  offsetAction,
} from "../../actions/lotAction";
import ExecutedActions from "./subscreens/ExecutedActions";
import Photo from "./subscreens/Photo";
import DeleteLot from "./subscreens/Delete";
import OrderStatus from "./subscreens/OrderStatus";

const { Text, Title } = Typography;

class LotOverview extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.getLotAction(params);
  }

  state = {
    offset: null,
  };

  _offset = () => {
    let params = {
      id: this.props.match.params.id,
      offset: this.state.offset,
    };

    this.props.offsetAction(params);
    setTimeout(() => {
      this.props.getLotAction({ id: this.props.match.params.id });
    }, 1000);
  };

  _alterStatus = (type) => {
    let params = {
      id: this.props.match.params.id,
      type: type,
    };

    this.props.putLotStatusAction(params);
    setTimeout(() => {
      this.props.getLotAction({ id: this.props.match.params.id });
    }, 1000);
  };

  render() {
    if (this.props.isFetching && !this.props.isError) {
      return <Spin />;
    } else if (!this.props.isFetching && this.props.isError) {
    }

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const nowDate = new Date();
    let progress = 0;
    let daysLeft = 0;
    let planted = this.props.lot.plant_id !== null ? true : false;

    if (planted) {
      let plantedDate = new Date(this.props.lot.planted_on);
      let diffDays = Math.round(Math.abs((nowDate - plantedDate) / oneDay));
      daysLeft = this.props.lot.grow_time - diffDays - this.props.lot.offset;
      progress = Math.round(
        ((this.props.lot.offset + diffDays) / this.props.lot.grow_time) * 100
      );
      console.log(daysLeft);
      console.log(progress);
    }
    if (this.props.lot.harvested > 0) {
      let harvestedDate = new Date(this.props.lot.harvested_on);
      let diffDays = Math.round(Math.abs((nowDate - harvestedDate) / oneDay));
      daysLeft =
        this.props.lot.days_between_harvest - diffDays - this.props.lot.offset;
      progress = Math.round(
        ((this.props.lot.offset + diffDays) /
          this.props.lot.days_between_harvest) *
          100
      );
      console.log(daysLeft);
      console.log(progress);
    }

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/field">Veld</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              onClick={() => {
                this.props.history.goBack();
              }}>
              Tuin
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="#">Perceel {this.props.lot.index}</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <Row style={{ marginBottom: 20 }}>
          <Col md={24} xl={12}>
            <Col style={{ marginBottom: 5, marginRight: 20 }}>
              <Title level={3}>General</Title>
              {this.props.lot.planted_on && (
                <div>
                  <Text>
                    Op dit perceel staat een{" "}
                    <Text strong>{this.props.lot.name}</Text>
                  </Text>
                  <br />
                  <Text>
                    Deze is op <Text strong>{this.props.lot.planted_on}</Text>{" "}
                    geplant voor de gebruiker
                  </Text>
                  <br />
                  { this.props.lot.really_planted_on && (
                    <Text>
                    Deze is op <Text strong>{this.props.lot.really_planted_on}</Text>{" "}
                    daadwerkelijk geplant
                    </Text>
                  )}
                  <br />
                  {this.props.lot.harvested > 0 && (
                    <Text>
                      Voor het laatst geoogst op{" "}
                      <Text strong>{this.props.lot.harvested_on}</Text>
                      <br />
                    </Text>
                  )}
                  <Progress percent={progress} />
                  <Text>
                    Nog ongeveer <Text strong>{daysLeft}</Text> dagen te gaan
                  </Text>
                  <h1>Afwijking</h1>
                  <InputNumber
                    defaultValue={this.props.lot.offset}
                    onChange={(value) => {
                      this.setState({ offset: value });
                    }}
                  />{" "}
                  <Button
                    type="primary"
                    style={{
                      marginBottom: 12,
                      marginLeft: 12,
                      marginRight: 10,
                    }}
                    onClick={this._offset}>
                    Aanpassen
                  </Button>
                  <DeleteLot {...this.props} />
                  {(this.props.lot.order_status === 1 ||
                    this.props.lot.order_status === 2) && (
                    <OrderStatus {...this.props} />
                  )}
                </div>
              )}

              {this.props.lot.send_status === 2 && (
                <Text strong style={{ color: "green" }}>
                  Voor dit perceel is verzending betaald!
                </Text>
              )}
              {this.props.lot.send_status === 1 && (
                <Text strong style={{ color: "orange" }}>
                  Voor dit perceel is NOG GEEN verzending betaald!
                </Text>
              )}
            </Col>

            <Col style={{ marginBottom: 5, marginRight: 20 }}>
              <Title level={3}>Status</Title>

              <Row gutter={[8, 8]}>
                <Col xs={24} sm={12} md={6}>
                  <Card title={"Water"} size={"small"} bordered={true}>
                    {this.props.lot.moist === 1 ? (
                      <div>
                        <Text style={{ color: "green" }}>Alles in orde</Text>
                        <br />
                        <Popconfirm
                          title="Are you sure you want to alert the user?"
                          onConfirm={() => this._alterStatus("moist")}
                          okText="Yes"
                          cancelText="No">
                          <Button type="primary" size={"small"}>
                            Actie vereist
                          </Button>
                        </Popconfirm>
                      </div>
                    ) : (
                      <Text style={{ color: "red" }}>Nog niet opgelost</Text>
                    )}
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <Card title={"Onkruid"} size={"small"} bordered={true}>
                    {this.props.lot.weeds === 1 ? (
                      <div>
                        <Text style={{ color: "green" }}>Alles in orde</Text>
                        <br />
                        <Popconfirm
                          title="Are you sure you want to alert the user?"
                          onConfirm={() => this._alterStatus("weeds")}
                          okText="Yes"
                          cancelText="No">
                          <Button type="primary" size={"small"}>
                            Actie vereist
                          </Button>
                        </Popconfirm>
                      </div>
                    ) : (
                      <Text style={{ color: "red" }}>Nog niet opgelost</Text>
                    )}
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <Card title={"Ongedierte"} size={"small"} bordered={true}>
                    {this.props.lot.pests === 1 ? (
                      <div>
                        <Text style={{ color: "green" }}>Alles in orde</Text>
                        <br />
                        <Popconfirm
                          title="Are you sure you want to alert the user?"
                          onConfirm={() => this._alterStatus("pests")}
                          okText="Yes"
                          cancelText="No">
                          <Button type="primary" size={"small"}>
                            Actie vereist
                          </Button>
                        </Popconfirm>
                      </div>
                    ) : (
                      <Text style={{ color: "red" }}>Nog niet opgelost</Text>
                    )}
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <Card title={"Bemesting"} size={"small"} bordered={true}>
                    {this.props.lot.fertilization === 1 ? (
                      <div>
                        <Text style={{ color: "green" }}>Alles in orde</Text>
                        <br />
                        <Popconfirm
                          title="Are you sure you want to alert the user?"
                          onConfirm={() => this._alterStatus("fertilization")}
                          okText="Yes"
                          cancelText="No">
                          <Button type="primary" size={"small"}>
                            Actie vereist
                          </Button>
                        </Popconfirm>
                      </div>
                    ) : (
                      <Text style={{ color: "red" }}>Nog niet opgelost</Text>
                    )}
                  </Card>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <Card title={"Oogsten"} size={"small"} bordered={true}>
                    {this.props.lot.harvestable === 0 ? (
                      <div>
                        <Popconfirm
                          title="Are you sure you want to alert the user?"
                          onConfirm={() => this._alterStatus("harvestable")}
                          okText="Yes"
                          cancelText="No">
                          <Button type="primary" size={"small"}>
                            Perceel kan worden geoogst
                          </Button>
                        </Popconfirm>
                      </div>
                    ) : (
                      <Text style={{ color: "red" }}>
                        Gebruiker heeft nog niet geoogst
                      </Text>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col md={24} xl={12}>
            <Title level={3}>Acties</Title>

            <ExecutedActions {...this.props} />
          </Col>
        </Row>

        <Row>
          <Col md={24} xl={12}>
            <Title level={3}>Photo</Title>

            <Photo {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.lotReducer.isFetching,
    isError: state.lotReducer.isError,
    lot: state.lotReducer.lot,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLotAction: bindActionCreators(getLotAction, dispatch),
    putLotStatusAction: bindActionCreators(putLotStatusAction, dispatch),
    offsetAction: bindActionCreators(offsetAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LotOverview);
