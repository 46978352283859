import { Col, Input, Upload, Button, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { postPhoto, getPhotos } from '../../../actions/demoPlantPhotoAction';
// import { deletePlantAction } from '../../../actions/plantAction';

const openNotificationWithIcon = type => {
  notification[type]({
    message: "Upload geslaagd",
    description: "De foto is geupload",
  });
};

class DemoPlantPhotos extends React.Component {
  componentDidMount() {
    this.props.getPhotos({plant_id: this.props.match.params.id});
  }

  state = {
    fileList: [],
    updated: false,
  };

  handleInputChange = (event) => {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
        [name] : value
    });
  }

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();

    formData.append("plant_id", this.props.match.params.id);
    formData.append("day", this.state.day);
    formData.append("file", fileList[0]);

    console.log(fileList);

    this.props.postPhoto(formData);

    this.setState({
      updated: false,
      day: 0,
      fileList: [],
    });
  };

  componentDidUpdate() {
    if (this.props.isSuccess && !this.state.updated) {
      openNotificationWithIcon("success");
      this.props.getPhotos({ plant_id: this.props.match.params.id });
      this.setState({
        updated: true,
      });
    }
  }

  render() {
    const { fileList } = this.state;
    const props = {
      multiple: false,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const content = this.props.photos.map((item, key) => {
      return (
        <div>
          <img key={key} src={"http://api.mijnboerderijtje.nl/uploads/" + item.filename } width={"100%"} alt="Logo" />
          <p>Dag {item.day}</p>
        </div>
      )
    });

    return (
      <div>
        <Upload {...props} accept=".jpg">
          <Button>
            <UploadOutlined /> Select File
          </Button>
        </Upload>
        <p>Dag </p>
        <Input
          type="number"
          placeholder={"dag"}
          name="day"
          style={{width: 200}}
          onChange={this.handleInputChange}
          defaultValue={"0"}
        />
        <br/>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={this.props.isPosting}
          style={{ marginTop: 16, marginBottom: 16 }}>
          {this.props.isPosting ? "Uploading" : "Start Upload"}
        </Button>

        <h1>Onderstaande foto's zijn al geupload</h1>
        <Col md={24} xl={12}>
        {content}
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isPosting: state.demoPlantPhotoReducer.isPosting,
    isSuccess: state.demoPlantPhotoReducer.isSuccess,
    isError: state.demoPlantPhotoReducer.isError,
    photos: state.demoPlantPhotoReducer.photos,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postPhoto : bindActionCreators(postPhoto, dispatch),
    getPhotos : bindActionCreators(getPhotos, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoPlantPhotos);
