import { Button, Row, Col, InputNumber } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getCampaignsAction,
  postCampaignVouchersAction,
  postCampaignCreditVouchersAction,
} from "../../actions/campaignAction";
import VoucherTable from "./subscreens/VoucherTable";
import CreditVoucherTable from "./subscreens/CreditVoucherTable";
import GeneralVouchersTable from "./subscreens/GeneralVouchersTable";

class CampaignDetail extends React.Component {
  componentDidMount() {
    let params = {
      id: this.props.match.params.id,
    };

    this.props.getCampaignsAction(params);
  }

  state = {
    amount: 1,
    amount_of_credits: 1,
    amount_of_vouchers: 1,
  };

  generateVouchers = () => {
    let params = {
      campaign_id: this.props.match.params.id,
      amount: this.state.amount,
    };

    this.props.postCampaignVouchersAction(params);
  };

  generateCreditVouchers = () => {
    let params = {
      campaign_id: this.props.match.params.id,
      amount_of_credits: this.state.credits,
      amount: this.state.amount_of_vouchers,
    };

    console.log(params);

    this.props.postCampaignCreditVouchersAction(params);
  };

  onChange = value => {
    this.setState({ amount: value });
    console.log("changed", value);
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={24} lg={6}>
            <h1>Tuin vouchers</h1>
            <InputNumber min={1} defaultValue={1} onChange={this.onChange} />
            <Button
              type="primary"
              style={{ marginBottom: 12, marginLeft: 12 }}
              onClick={this.generateVouchers}>
              Genereer vouchers
            </Button>

            <VoucherTable {...this.props} />
          </Col>
          <Col sm={24} lg={1} />
          <Col sm={24} lg={7}>
            <h1>Credit vouchers</h1>
            <InputNumber
              min={1}
              defaultValue={1}
              onChange={value => {
                this.setState({ credits: value });
              }}
            />
            credits
            <InputNumber
              min={1}
              defaultValue={1}
              onChange={value => {
                this.setState({ amount_of_vouchers: value });
              }}
            />{" "}
            vouchers
            <Button
              type="primary"
              style={{ marginBottom: 12, marginLeft: 12 }}
              onClick={this.generateCreditVouchers}>
              Genereer vouchers
            </Button>
            <CreditVoucherTable {...this.props} />
          </Col>
          <Col sm={24} lg={1} />
          <Col sm={24} lg={8}>
            <GeneralVouchersTable {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.campaignReducer.isFetching,
    isError: state.campaignReducer.isError,
    campaigns: state.campaignReducer.campaigns,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCampaignsAction: bindActionCreators(getCampaignsAction, dispatch),
    postCampaignVouchersAction: bindActionCreators(
      postCampaignVouchersAction,
      dispatch
    ),
    postCampaignCreditVouchersAction: bindActionCreators(
      postCampaignCreditVouchersAction,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail);
