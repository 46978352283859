import api from '../utils/api'

// EX Action stands for:
// Executable Action
// To tackle confusion

export const getExActionsAction = (params) => dispatch => {
  dispatch({
    type: 'EX_ACTION_ACTION_FETCH'
  })
  return api('get','/api/actions', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'EX_ACTION_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'EX_ACTION_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'EX_ACTION_ACTION_ERROR',
    })
    return false;
  });
}

export const postExActionAction = (params) => dispatch => {
  dispatch({
    type: 'EX_ACTION_ACTION_POST'
  })
  return api('post','/api/actions', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'EX_ACTION_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'EX_ACTION_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'EX_ACTION_ACTION_ERROR',
    })
    return false;
  });
}
