import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Spin, Rate } from 'antd';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPlantStarsAction, putPlantStarsAction } from '../../../actions/plantStarAction';

const { Title } = Typography;

class PlantStars extends React.Component {

  componentDidMount(){
    let params = {
        plant_id : this.props.match.params.id
    }

    this.props.getPlantStarsAction(params);
  }

  state = {
      id: 0,
      storage: 0,
      usage: 0,
      vitamin: 0,
      energy: 0,
      carb: 0,
      fibers: 0,
      tracers: 0
  }

  _handleStorage = (value) => {
      this._handleChange("storage", value);
  }

  _handleUsage = (value) => {
      this._handleChange("usage", value);
  }

  _handleVitamin = (value) => {
      this._handleChange("vitamin", value);
  }

  _handleEnergy = (value) => {
      this._handleChange("energy", value);
  }

  _handleCarb = (value) => {
      this._handleChange("carb", value);
  }

  _handleFibers = (value) => {
      this._handleChange("fibers", value);
  }

  _handleTracers = (value) => {
      this._handleChange("tracers", value);
  }

  _handleChange = (name, value) => {
      this.setState({
          [name]: value
      }, () => {this.props.putPlantStarsAction(this.state)});
  }

  render(){
    if (this.props.isFetching && !this.props.isError) {
      return (<Spin />)
    } else if (!this.props.isFetching && this.props.isError) {

    }

    if (this.state.storage === 0 && this.props.plantStars.storage) {
        this.setState({
            id: this.props.plantStars.id,
            storage: this.props.plantStars.storage,
            usage: this.props.plantStars.usage,
            vitamin: this.props.plantStars.vitamin,
            energy: this.props.plantStars.energy,
            carb: this.props.plantStars.carb,
            fibers: this.props.plantStars.fibers,
            tracers: this.props.plantStars.tracers
        },()=>console.log('State updated', this.state))
    }

    return(
      <div>
          <Title level={4}>Voedingswaarden</Title>
          <p>Deze waardes worden automatisch opgeslagen!</p>
          <Form>
            <Form.Item label="Houdbaarheid" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.storage} onChange={this._handleStorage}/>
            </Form.Item>
            <Form.Item label="Bereiding" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.usage} onChange={this._handleUsage}/>
            </Form.Item>
            <Form.Item label="Vitamines" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.vitamin} onChange={this._handleVitamin}/>
            </Form.Item>
            <Form.Item label="Calorieën" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.energy} onChange={this._handleEnergy}/>
            </Form.Item>
            <Form.Item label="Koolhydraten" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.carb} onChange={this._handleCarb}/>
            </Form.Item>
            <Form.Item label="Vezels" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.fibers} onChange={this._handleFibers}/>
            </Form.Item>
            <Form.Item label="Mineralen" style={{marginBottom: 0}}>
              <Rate allowClear={false} defaultValue={this.props.plantStars.tracers} onChange={this._handleTracers}/>
            </Form.Item>
          </Form>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
    return {
      isFetching: state.plantStarReducer.isFetching,
      isError: state.plantStarReducer.isError,
      plantStars: state.plantStarReducer.plantStars,
    };
}

const mapDispatchToProps = (dispatch) => {
  return {
     getPlantStarsAction : bindActionCreators(getPlantStarsAction, dispatch),
     putPlantStarsAction : bindActionCreators(putPlantStarsAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantStars);
