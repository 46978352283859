export default (state = {isError: false, isFetching: false, isPosting: false, motd: [] }, action) => {
    switch (action.type) {
    case 'MOTD_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
    }
    case 'MOTD_ACTION_POST':
    return {
        ...state,
        isPosting: true,
        isError: false,
    }
    case 'MOTD_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        motd: action.payload
    }
    case 'MOTD_ACTION_POST_SUCCESS':
    return {
        ...state,
        isPosting: false,
        isError: false,
        isFetching: false
    }
    case 'MOTD_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isFetching: false
    }
    default:
        return state
    }
}
