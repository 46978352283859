import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, InputNumber } from 'antd';
const { TextArea } = Input;

const NewBusinessTimelineForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;

        return (
          <Modal
            visible={visible}
            title="Maak een nieuwe entry aan"
            okText="OK"
            onCancel={onCancel}
            onOk={onCreate}
          >
            <Form layout="vertical">
                <Form.Item label="Dag">
                  {getFieldDecorator('day', {
                    rules: [{ required: true, message: 'Geef een dag op!' }],
                })(<InputNumber min={1} max={365} />)}
                </Form.Item>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [{ required: true, message: 'Geef een titel op!' }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Message">
              {getFieldDecorator('message', {
                rules: [{ required: true, message: 'Geef een message op!' }],
              })(<TextArea rows={4} />)}
              </Form.Item>
              <Form.Item label="Youtube Video">
              {getFieldDecorator('youtube_link', {
                rules: [{ required: false, message: 'Geef een link op!' }],
            })(<Input addonBefore="https://www.youtube.com/watch?v=" ></Input>)}
              </Form.Item>
            </Form>
          </Modal>
        );
        }
    }
);

export default NewBusinessTimelineForm;
