export default (state = {isError: false, isFetching: false, isPosting: false, isPutting: false, isSuccess: false, isDeleting: false, plants: [], currentPlant:{} }, action) => {
    switch (action.type) {
    case 'PLANT_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
        isPosting: false,
        isSuccess: false
    }
    case 'SINGLE_PLANT_ACTION_FETCH':
    return {
        ...state,
        isFetching: true,
        isError: false,
        isPosting: false,
        isSuccess: false
    }
    case 'SINGLE_PLANT_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
        currentPlant: action.payload
    }
    case 'PLANT_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
        plants: action.payload
    }
    case 'PLANT_ACTION_ERROR':
    return {
        ...state,
        isError: true,
        isPosting: false,
        isFetching: false,
        isPutting: false,
        isSuccess: false
    }
    case 'PLANT_ACTION_POST':
    return {
        ...state,
        isError: false,
        isPosting: true,
        isFetching: false
    }
    case 'PLANT_POST_ACTION_SUCCESS':
    return {
        ...state,
        isFetching: false,
        isError: false,
        isPosting: false,
    }
    case 'PLANT_ACTION_PUT':
    return {
        ...state,
        isPutting: true,
        isError: false,
        isPosting: false,
        isSuccess: false
    }
    case 'PLANT_PUT_ACTION_SUCCESS':
    return {
        ...state,
        isPutting: false,
        isError: false,
        isPosting: false,
        isSuccess: true
    }
    case 'PLANT_ACTION_DELETE':
    return {
        ...state,
        isDeleting: true,
        isPutting: false,
        isError: false,
        isPosting: false,
        isSuccess: false
    }
    case 'PLANT_DELETE_ACTION_SUCCESS':
    return {
        ...state,
        isDeleting: false,
        isPutting: false,
        isError: false,
        isPosting: false,
        isSuccess: false
    }
    default:
        return state
    }
}
