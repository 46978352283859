import api from '../utils/api'

export const getPlantsAction = () => dispatch => {
  dispatch({
    type: 'PLANT_ACTION_FETCH'
  })
  return api('get','/api/plants', null, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_ACTION_SUCCESS',
        payload: response.data
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_ACTION_ERROR',
    })
    return false;
  });
}

export const getSinglePlantAction = (params) => dispatch => {
  dispatch({
    type: 'SINGLE_PLANT_ACTION_FETCH'
  })
  return api('get','/api/plants', params, true).then((response) => {
    if(response.data.success) {
      console.log(response.data.data)
      dispatch({
        type: 'SINGLE_PLANT_ACTION_SUCCESS',
        payload: response.data.data
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_ACTION_ERROR',
    })
    return false;
  });
}

export const putSinglePlantAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_ACTION_PUT'
  })
  return api('put','/api/plants', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_PUT_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_ACTION_ERROR',
    })
    return false;
  });
}


export const postPlantAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_ACTION_POST'
  })
  return api('post','/api/plants', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_POST_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_ACTION_ERROR',
    })
    return false;
  });
}

export const deletePlantAction = (params) => dispatch => {
  dispatch({
    type: 'PLANT_ACTION_DELETE'
  })
  return api('delete','/api/plants', params, true).then((response) => {
    if(response.data.success) {
      dispatch({
        type: 'PLANT_DELETE_ACTION_SUCCESS',
      });
      return true;
    } else {
      dispatch({
        type: 'PLANT_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'PLANT_ACTION_ERROR',
    })
    return false;
  });
}
