import api from '../utils/api'

export const loginAction = (params) => dispatch => {
  dispatch({
    type: 'LOGIN_ACTION_FETCH'
  })
  return api('post','/api/login', params, false).then((response) => {
    if(response.data.success) {
      localStorage.setItem('authToken', response.data.token);
      dispatch({
        type: 'LOGIN_ACTION_SUCCESS',
        payload: 'user'
      });
      return true;
    } else {
      dispatch({
        type: 'LOGIN_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOGIN_ACTION_ERROR',
    })
    return false;
  });
}

export const getLoginUserAction = () => dispatch => {
  dispatch({
    type: 'LOGIN_USER_ACTION_FETCH'
  })
  return api('get','/user/?token='+localStorage.getItem('authToken'), null, true).then((response) => {
    if(response.data) {
      dispatch({
        type: 'LOGIN_USER_ACTION_SUCCESS',
        payload: response.data
      });
      return true;
    } else {
      dispatch({
        type: 'LOGIN_USER_ACTION_ERROR',
      })
      return false;
    }
  }).catch((e) => {
    dispatch({
      type: 'LOGIN_USER_ACTION_ERROR',
    })
    return false;
  });
}

export const switchCustomerAction = (customer) => dispatch => {
  dispatch({
    type: 'CUSTOMER_ACTION_SWITCH',
    payload: customer
  })
}

export const logoutAction = () => dispatch => {
  dispatch({
    type: 'USER_LOGOUT'
  })
  localStorage.removeItem('authToken')
}
