import { Typography, Spin, List, Button } from "antd";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getExecutedActions,
  putExecutedActions,
} from "../../../actions/actionsExecutedAction";
import { getLotAction } from "../../../actions/lotAction";

const { Text } = Typography;

class ExecutedActions extends React.Component {
  componentDidMount() {
    let params = {
      lot_id: this.props.match.params.id,
    };

    this.props.getExecutedActions(params);
  }

  _alterStatus = (id) => {
    let params = {
      id: id,
    };
    this.props.putExecutedActions(params);
    setTimeout(() => {
      this.props.getExecutedActions({ lot_id: this.props.match.params.id });
      this.props.getLotAction({ id: this.props.match.params.id });
    }, 1000);
  };

  _renderButton = (executed_on, id) => {
    if (executed_on) {
      return (
        <Button type="dashed" size={"small"} disabled={true}>
          Uitgevoerd
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          size={"small"}
          onClick={() => this._alterStatus(id)}>
          Voer uit
        </Button>
      );
    }
  };

  render() {
    if (this.props.isFetching && !this.props.isError) {
      return <Spin />;
    } else if (!this.props.isFetching && this.props.isError) {
    }

    const content = this.props.actionsExecuted.map((item, key) => {
      if (item.action_id) {
        return (
          <List.Item
            key={key}
            actions={[this._renderButton(item.executed_on, item.id)]}>
            <List.Item.Meta
              title={item.action_name}
              description={"Aangevraagd op: " + item.created_at}
            />
          </List.Item>
        );
      } else if (item.plant_id) {
        return (
          <List.Item
            key={key}
            actions={[this._renderButton(item.executed_on, item.id)]}>
            <List.Item.Meta
              title={"Bestellen: " + item.plant_name}
              description={"Aangevraagd op: " + item.created_at}
            />
          </List.Item>
        );
      }
    });

    return <List>{content}</List>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.actionsExecutedReducer.isFetching,
    isError: state.actionsExecutedReducer.isError,
    actionsExecuted: state.actionsExecutedReducer.actionsExecuted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExecutedActions: bindActionCreators(getExecutedActions, dispatch),
    putExecutedActions: bindActionCreators(putExecutedActions, dispatch),
    getLotAction: bindActionCreators(getLotAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutedActions);
